import styles from "./whatsAppLinkPopup.module.scss";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {Loader} from "../loader/loader";
import {useEffect, useState} from "react";
import {ModalContainer} from "../modalContainer/modalContainer";
import {useUserContext} from "../../context/UserContext";
import {QRCodeCanvas} from "qrcode.react";

type Props = {
	setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
	setShowModalWaiting: React.Dispatch<React.SetStateAction<boolean>>;
	link: string;
	showModalWaiting: boolean;
};

export const WhatsAppLinkPopup: React.FC<Props> = ({setShowModal, link, setShowModalWaiting, showModalWaiting}) => {
	const [t, i18n] = useTranslation("common");
	const {setSurveyContent} = useUserContext();
	const [load, setLoad] = useState(false);
	const joined = true;
	const [buttonDisabled, setButtonDisabled] = useState(true);

	useEffect(() => {
		const timer = setTimeout(() => {
			setButtonDisabled(false);
		}, 2500);

		return () => clearTimeout(timer);
	}, []);

	const closeModals = () => {
		setShowModal(false);
		setShowModalWaiting(false);
	};

	return (
		<ModalContainer setShowModal={setShowModal} modalSide={false} showModal={true}>
			<div className={styles.menuContainer}>
				{/*If the waiting model is open, it means that the student has just joined the group and chose the option to join manually, therefore we don't want to show the close icon as a way to force him to click on the "I joined button" */}
				{!showModalWaiting && <div className={classNames(styles.close, i18n.language === "ar" && styles.closeAr, joined && styles.close2)} onClick={() => setShowModal(false)} style={{backgroundImage: "url(/assets/modal_bottom_sheet/close.svg)"}}></div>}
				{load && <Loader />}
				{/* <div className={classNames(styles.img, i18n.language === "ar" && styles.imgAr)} style={{backgroundImage: "url(/assets/modal_hodaot/modal_win.svg)"}}></div> */}
				<div className={styles.title}>{t("whatsappLinkPopup.guideText")}</div>
				<div className={classNames(styles.description)}>
					<a href={link} target="_blank" className={classNames(styles.link)} rel="noreferrer">
						{link}
					</a>
				</div>
				<div className={styles.question}>{t("whatsappLinkPopup.or")}</div>
				<QRCodeCanvas style={{marginTop: "15px", marginBottom: "20px"}} value={link} />
			</div>
			<div className={buttonDisabled ? styles.buttonContainerDisabled : styles.bottonContainer} onClick={e => (buttonDisabled ? null : closeModals())}>
				{t("whatsappLinkPopup.joined")}
			</div>
		</ModalContainer>
	);
};
