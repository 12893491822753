import {DeviceType, useScale} from "../../../hooks/use-scale";
import {LoginButton} from "../../../components/loginButton/loginButton";
import styles from "./section1.module.scss";
import classNames from "classnames";
import {useTranslation} from "react-i18next";

type Props = {
	SSOLink: string | null;
};

export const Section1: React.FC<Props> = ({SSOLink}) => {
	const {deviceType} = useScale();
	const [t, i18n] = useTranslation("common");

	const scrollDown = () => {
		let element = document.getElementById("section2");
		element?.scrollIntoView({behavior: "smooth"});
	};

	const getBackground = () => {
		return deviceType === DeviceType.mobile ? "url(/assets/section1-top/m480-bg-top.svg)" : "url(/assets/section1-top/desktop_bg_top.svg)";
	};

	return (
		<div className={styles.section1} style={{backgroundImage: getBackground()}}>
			<div className={styles.contentSection1}>
				<div className={styles.logo} style={{backgroundImage: t("header.logoUrl")}}></div>
				<div className={classNames(styles.txtExplain, i18n.language === "ar" && styles.txtExplainAr)}>
					{t("section1.txtStart")
						.trim()
						.split(" ")
						.map((word: string, i: number) => {
							return <span key={i}>{word}&nbsp;</span>;
						})}
					{t("section1.txtBold")
						.trim()
						.split(" ")
						.map((word: string, i: number) => {
							return (
								<span style={{fontWeight: "bold"}} key={i}>
									{word}&nbsp;
								</span>
							);
						})}
					{t("section1.txtEnd")
						.trim()
						.split(" ")
						.map((word: string, i: number) => {
							return <span key={i}>{word}&nbsp;</span>;
						})}
				</div>
				<LoginButton SSOLink={SSOLink} />
				<div onClick={scrollDown} className={classNames(styles.btnArrow)} style={{backgroundImage: "url(/assets/section1-top/btn_down_arrow/btn_bg_down-arrow.svg)"}}>
					<div className={styles.downArrow} style={{backgroundImage: "url(/assets/section1-top/btn_down_arrow/down-arrow.svg)"}}></div>
				</div>
			</div>
		</div>
	);
};
