import { DeviceType, useScale } from "../../../hooks/use-scale";
import { LoginButton } from "../../../components/loginButton/loginButton";
import styles from "./section3.module.scss";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

export const Section3 = () => {
    const { deviceType } = useScale();
    const [t, i18n] = useTranslation('common');

    return (
        <div className={classNames(styles.section3, i18n.language === 'ar' && styles.section3Ar)}>
            <div className={styles.content}>
                <div className={classNames(styles.boxTxt1, i18n.language === 'ar' && styles.boxTxt1Ar)}>
                    <div className={classNames(styles.txt1, i18n.language === 'ar' && styles.txt1Ar)}>{t('section3.txtStartTop')}</div>
                    <div className={classNames(styles.txt2, i18n.language === 'ar' && styles.txt2Ar)} style={{ fontWeight: "bold" }}>{t('section3.txtBoldTop')}</div>
                </div>
                <div className={styles.img1} style={{ backgroundImage: 'url(/assets/section3_illustrations/ill1.svg)' }}></div>
                <div className={classNames(styles.arrow1, i18n.language === 'ar' && styles.arrow1Ar)} style={{ backgroundImage: deviceType === DeviceType.mobile ? 'url(/assets/section3_illustrations/m-arrow-1.svg)' : 'url(/assets/section3_illustrations/desktop-arrow-1.svg)' }}></div>
                {deviceType !== DeviceType.mobile && <div className={styles.img2} style={{ backgroundImage: 'url(/assets/section3_illustrations/ill2.svg)' }}></div>}
                <div className={styles.boxTxt2}>
                    <div className={styles.txt3} style={{ fontWeight: "bold" }}>{t('section3.txtBold')}</div>
                    <div className={classNames(styles.txt, i18n.language === 'ar' && styles.txtAr)}>
                        {t('section3.txtEnd1').trim().split(' ').map((word: string, i: number) => {
                            return <span key={i}>{word}&nbsp;</span>
                        })}
                        {t('section3.txtEndBold1').trim().split(' ').map((word: string, i: number) => {
                            return <span style={{ fontWeight: "bold" }} key={i}>{word}&nbsp;</span>
                        })
                        }
                        {t('section3.txtEnd2').trim().split(' ').map((word: string, i: number) => {
                            return <span key={i}>{word}&nbsp;</span>
                        })}
                        {t('section3.txtEndBold2').trim().split(' ').map((word: string, i: number) => {
                            return <span style={{ fontWeight: "bold" }} key={i}>{word}&nbsp;</span>
                        })
                        }
                    </div>
                </div>
                {deviceType === DeviceType.mobile && <div className={styles.img2} style={{ backgroundImage: 'url(/assets/section3_illustrations/ill2.svg)' }}></div>}
                <div className={classNames(styles.arrow2, i18n.language === 'ar' && styles.arrow2Ar)} style={{ backgroundImage: deviceType === DeviceType.mobile ? 'url(/assets/section3_illustrations/m-arrow-2.svg)' : 'url(/assets/section3_illustrations/desktop_arrow-2.svg)' }}></div>
            </div>
            <div className={styles.boxLogin}>
                <LoginButton shadow={true} />
            </div>
        </div>
    )
}