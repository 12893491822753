import styles from "./warningPopUP.module.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Loader } from '../loader/loader';
import { useEffect, useState } from "react";
import { ModalContainer } from "../modalContainer/modalContainer";
import { useUserContext } from "../../context/UserContext";

type Props = {
    type?: string,
    groupMatchId?: number,
    check?: string;
    setCheck?: React.Dispatch<React.SetStateAction<string>>,
    edit?: Function,
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
    setLeaveGroup?: React.Dispatch<React.SetStateAction<boolean>>,
    joined?: string,
    updatedList?: boolean,
    setUpdatedList?: React.Dispatch<React.SetStateAction<boolean>>,
}

export const WarningPopUp: React.FC<Props> = ({ type, edit, setShowModal, groupMatchId, setCheck, check, setLeaveGroup, joined, updatedList, setUpdatedList }) => {
    const [t, i18n] = useTranslation('common');
    const { setSurveyContent } = useUserContext();
    const [load, setLoad] = useState(false);

    useEffect(() => {
        return () => {
            joined !== 'added' && setUpdatedList && setUpdatedList(!updatedList);
            document.body.style.overflowY = 'auto';
        }
    }, [])

    const handleContinu = async () => {
        document.body.style.overflowY = 'auto';
        if (groupMatchId) {
            console.log("type", type, groupMatchId);

            if (type === 'a') {
                setCheck && check && setCheck(check);
                edit && edit();
                setShowModal(false);
                console.log('out!!');

            }
            else {
                setLoad(true);
                if (type === 'b') {
                    axios.post('/api/Groups/LeaveGroupsMatch', `"${groupMatchId}"`, { headers: { 'Content-Type': 'application/json' } })
                    .then((res: any) => {
                        console.log('leaving path', res);
                        
                        setLoad(false);
                        if(joined === 'added' && setLeaveGroup && setSurveyContent){  
                            setSurveyContent(res.data.content)
                            setLeaveGroup(true);
                        }
                        setShowModal(false);
                    })
                }
                else {
                    axios.post('/api/Groups/LeaveGroup', `"${groupMatchId}"`, { headers: { 'Content-Type': 'application/json' } })
                    .then((res: any) => {
                        console.log('leaving group', res);
                        setLoad(false);
                        if(joined === 'added' && setLeaveGroup && setSurveyContent){  
                            setSurveyContent(res.data.content)
                            setLeaveGroup(true);
                        }
                        setShowModal(false);
                    })
                }
            }
        }
        else {
            edit && edit(true);
            setShowModal(false);
        }
    }

    return (
        <ModalContainer setShowModal={setShowModal} modalSide={false} showModal={true} >
            <div className={styles.menuContainer}>
                <div className={classNames(styles.close, i18n.language === 'ar' && styles.closeAr, joined && styles.close2)} onClick={() => setShowModal(false)} style={{ backgroundImage: 'url(/assets/modal_bottom_sheet/close.svg)' }}></div>
                {load && <Loader />}
                <div className={classNames(styles.img, i18n.language === 'ar' && styles.imgAr)} style={{ backgroundImage: (type === "tel" || type === "gender") ? 'url(/assets/modal_hodaot/modal_rega.svg)' : 'url(/assets/modal_hodaot/modal_warning.svg)' }}></div>
                <div className={styles.title}>{(type === "tel" || type === "gender") ? t('warningPopUp.wait') : t('warningPopUp.attention')}</div>

                <div className={classNames(styles.description, type === "gender" && styles.gender, !type && styles.group)}>
                    {type === "tel" ? t('warningPopUp.tel') : type === "gender" ? t('warningPopUp.gender') : t('warningPopUp.group')}
                </div>

                <div className={styles.question}>{(type === "tel" || type === "gender") ? t('warningPopUp.change') : t('warningPopUp.continue')}</div>
     
               {type === "tel" && <div className={styles.tel}>
                    {t('warningPopUp.delayWarning')}
                </div>}
                
                <div className={styles.bottonContainer}>
                    <div className={styles.no} onClick={() => {
                        setShowModal(false);
                        document.body.style.overflowY = 'auto';
                    }}>{t('warningPopUp.no')}</div>
                    <div className={styles.yes} onClick={handleContinu}>{type === "tel" ? t('warningPopUp.yesTel') : type === "gender" ? t('warningPopUp.yesGender') : t('warningPopUp.yesGroup')}</div>
                </div>

            </div>
        </ModalContainer>
    )
}