import { LoginButton } from '../../../components/loginButton/loginButton'
import DottedDevider from '../../../components/dottedDevider/dottedDevider';
import { DeviceType, useScale } from '../../../hooks/use-scale';
import styles from "./section4.module.scss";
import { FlexableQuote } from '../../../components/flexableQuote/flexableQuote';
import { useTranslation } from 'react-i18next';
import React from 'react';
import classNames from 'classnames';

export const Section4 = () => {
    const { deviceType, scale } = useScale();
    const [t, i18n] = useTranslation('common');

    const getBackground = (path: string) => {
        return { backgroundImage: `url(${path})` };
    };

    const renderQuotes = () => {
        return (
            (t('section4.quotes', { returnObjects: true }) as any[]).map((q, i) =>
                <div key={q.text}>
                    {deviceType === DeviceType.desktop && i === 4 && <div style={{ paddingTop: 100 * scale + 'px' }}></div>}<FlexableQuote text={q.text} name={q.studentName} imgUrl={q.imageUrl} />
                </div>
            )
        )
    }

    return <div className={styles.container}>
        <div className={classNames(styles.train1, i18n.language === 'ar' && styles.train1Ar)} style={deviceType === DeviceType.desktop ? getBackground("/assets/section4_quotes/desktop-bg-4.svg") : getBackground(i18n.language === 'ar' ? "/assets/section4_quotes/m-bg-3-ar.svg" : "/assets/section4_quotes/m-bg-3.svg")}></div>
        <div className={styles.train2} style={getBackground(`/assets/section4_quotes/${deviceType === DeviceType.mobile ? 'm-bg-4' : 'desktop-bg-5'}.svg`)}></div>
        <div className={styles.content}>
            <div className={classNames(styles.surveyTitle, i18n.language === 'ar' && styles.surveyTitleAr)}>
                <div>{t('section4.surveyTitle1')}</div>
                <div>{t('section4.surveyTitle2')}
                    <div className={classNames(styles.textWave, i18n.language === 'ar' && styles.textWaveAr)} style={getBackground("/assets/section2_icons/text-wave.svg")}></div>
                </div>
            </div>
            <div className={styles.card}>
                {(t('section4.surveyData', { returnObjects: true }) as any[]).map((item, index) =>
                    <React.Fragment key={index}>
                        <div className={styles.dataItem} >
                            <div className={styles.percentage}>
                                <div className={styles.percentageIcon}>%</div>
                                <div className={styles.percentageNum}>{item.percentage}</div>
                            </div>
                            <div className={styles.description}>
                                <div>{t('section4.description')}</div>
                                <div>{item.description}</div>
                            </div>
                        </div>
                        {index === 0 && <DottedDevider orientation={deviceType === DeviceType.mobile ? 'horizontal' : 'vertical'} />}
                    </React.Fragment>
                )}
            </div>
            <div className={classNames(styles.credit, i18n.language === 'ar' && styles.creditAr)}>{t('section4.credit')}</div>
            <div className={classNames(styles.reccomendationsTitle, i18n.language === 'ar' && styles.reccomendationsTitleAr)}>
                <div className={styles.point} style={getBackground("/assets/section4_quotes/point.svg")}></div>
                {t('section4.point')}
            </div>

        </div>
        <div className={classNames(styles.reccomendations, i18n.language === 'ar' && styles.reccomendationsAr)}>
            {deviceType === DeviceType.mobile ?
                <div className={styles.wrapper}>{renderQuotes()}</div>
                : renderQuotes()
            }

        </div>
        <div className={styles.login}>
            <div className={classNames(styles.question, i18n.language === 'ar' && styles.questionAr)}>{t('section4.question')}</div>
            <LoginButton />
            <div className={styles.trainContainer} style={getBackground(`/assets/section4_quotes/${deviceType === DeviceType.mobile ? 'm-' : ""}bg-footer.svg`)}></div>
        </div>
    </div>
}