import styles from "./loginButton.module.scss";
import {Login} from "../../login/loginUtils";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import {useUserContext} from "../../context/UserContext";

type Props = {
	shadow?: boolean;
	SSOLink?: string | null;
};

export const LoginButton: React.FC<Props> = ({shadow, SSOLink}) => {
	const {userInfo} = useUserContext();

	const [t] = useTranslation("common");
	return (
		<div onClick={e => (!userInfo.isLoggedIn && SSOLink ? (window.location.href = SSOLink) : null)} className={classNames(styles.btnLogin, shadow && styles.shadow, userInfo.isLoggedIn && styles.fade)}>
			{t("section1.enter")}
		</div>
	);
};
//onClick={() => userInfo.isLoggedIn !== true && Login()}
