import {Section1} from "./section1/section1";
import {Section2} from "./section2/section2";
import {Section3} from "./section3/section3";
import {Section4} from "./section4/section4";
import {Footer} from "./footer/footer";
import {Header} from "../../components/header/header";
import styles from "./home.module.scss";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {useUserContext} from "../../context/UserContext";
import {UserDetails} from "../userDetails/user";

export const Home = (props: any) => {
	let params = useParams();
	const [SSOLink, setSSOLink] = useState<string | null>(null);
	const {setUserInfo, basicDetails, setBasicDetails, setPhoneValid, userInfo} = useUserContext();
	const navigate = useNavigate();

	useEffect(() => {
		if (params.fromDashboard === "fromDashboard") {
			let element = document.getElementById("section2");
			element?.scrollIntoView();
		} else window.scroll({top: 0});
	}, []);

	useEffect(() => {
		if (!userInfo.isLoggedIn) {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const whoAmIPromise = axios({
				method: "GET",
				url: "api/users/whoami",
				withCredentials: true,
			})
				.then((res: any) => {
					const login = res.data.content;
					setUserInfo(login);
				})
				.catch(() => {
					const loginPromise = axios({
						method: "GET",
						url: "/api/users/login",
						withCredentials: true,
					}).then(response => {
						setSSOLink(response.data?.redirectLocation);
					});
				});
		} else {
			if (!basicDetails.email || !basicDetails.gender || !basicDetails.phone) {
				navigate("/UserDetails");
				return;
			}
		}
	}, [userInfo]);

	return (
		<div className={styles.homeContainer}>
			<Header SSOLink={SSOLink}/>
			<Section1 SSOLink={SSOLink} />
			<Section2 />
			<Section3 />
			<Section4 />
			<Footer />
		</div>
	);
};
