import classNames from "classnames";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from './flexableQuote.module.scss';
type Props = {
  text: string;
  name: string;
  imgUrl: string;
}
const getBackground = (path: string) => {
  return { backgroundImage: `url(${path})` };
};

const addAnimations = () => {
  const animatedElements = document.querySelectorAll("#talkBubble");
  const windowOffsetTop = window.innerHeight;
  Array.prototype.forEach.call(animatedElements, (animatedQuote: HTMLElement) => {
    const animatedQuoteOffsetTop = animatedQuote.getBoundingClientRect().y;
    if (windowOffsetTop >= animatedQuoteOffsetTop) {
      animatedQuote.classList.add(styles.isVisible);
    } else {
      animatedQuote.classList.remove(styles.isVisible);
    }
  });
}

export const FlexableQuote: React.FC<Props> = ({ text, name, imgUrl }) => {
  const [t, i18n] = useTranslation('common');

  useEffect(() => {
    document.addEventListener("scroll", addAnimations);
    return () => { document.removeEventListener("scroll", addAnimations) };
  }, [])

  return (
    <div className={classNames(styles.talkBubble, i18n.language === 'ar' && styles.talkBubbleAr, styles.triRight, styles.round, styles.btmRight)} id="talkBubble">
      <div className={styles.avatar} style={getBackground('/assets/section4_quotes/avatar-frame.svg')}>
        <img src={imgUrl} alt="student" />
      </div>
      <div className={styles.talktext}>
        <div className={styles.quoteMark} style={getBackground('/assets/section4_quotes/quote_mark.svg')}></div>
        <div>{text}</div>
        <div className={classNames(styles.name, i18n.language === 'ar' && styles.nameAr)}>{name}</div>
      </div>
    </div>
  )
}