import styles from "./modalWaiting.module.scss";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import {ModalContainer} from "../modalContainer/modalContainer";
import {useEffect} from "react";
import {Lottie} from "../lottie/lottie";

type Props = {
	status: string;
	success: boolean;
	setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
	setUpdatedList: React.Dispatch<React.SetStateAction<boolean>>;
	setShowWhatsAppLink: React.Dispatch<React.SetStateAction<boolean>>;
	whatsAppInviteLink?: string;
	updatedList: boolean;
};

export const ModalWaiting: React.FC<Props> = ({status, success, setShowModal, setShowWhatsAppLink, whatsAppInviteLink, setUpdatedList, updatedList}) => {
	const [t, i18n] = useTranslation("common");
	useEffect(() => {
		return () => setUpdatedList(!updatedList);
	}, []);

	const closeModalAndShowLink = () => {
		setShowWhatsAppLink(true);
	};

	return (
		<ModalContainer setShowModal={setShowModal} modalSide={false} showModal={true} success={success}>
			<div className={classNames(styles.menuContainer, success && styles.con)}>
				{!success && <div className={styles.close} onClick={() => setShowModal(false)} style={{backgroundImage: "url(/assets/modal_bottom_sheet/close.svg)"}}></div>}
				{/* {success && <Lottie path={"/assets/modal_hodaot/confetti.json"} loop={true} className={styles.confetti} />} */}
				<div className={classNames(styles.img, success && styles.img2)} style={{backgroundImage: success ? "url(/assets/modal_hodaot/modal_win.svg)" : "url(/assets/modal_hodaot/modal_waitlist.svg)"}}></div>
				<div className={classNames(styles.title, success && styles.title2, i18n.language === "ar" && styles.arabTitle)}>{success ? t("joinedGroup.title") : t("waitingPopUp.title")}</div>
				<div className={classNames(styles.description, success && styles.des)}>{success ? (whatsAppInviteLink ? t("joinedGroup.description") : t("joinedGroup.descriptionNoLink")) : t("waitingPopUp.description")}</div>
				{!success && (
					<div className={styles.bottonContainer} onClick={() => setShowModal(false)}>
						{t("waitingPopUp.understood")}
					</div>
				)}

				{success && status !== "added" && status !== "queue" && whatsAppInviteLink !== "" && (
					<div className={styles.bottonContainer} onClick={closeModalAndShowLink}>
						{t("joinedGroup.joinManually")}
					</div>
				)}

				{/*ADD THE BUTTON HERE NEXT TO THIS, WHERE IF STATUS == JOINED THEN SHOW A MESSAGE THAT DISPLAYS THE WHATSAPPCODE AND CLOSES THE MODAL */}
			</div>
		</ModalContainer>
	);
};
