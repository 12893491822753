import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import {MyPath} from "../../components/myPath/myPath";
import {PageBackground} from "../../components/pageBackground/pageBackground";
import {useUserContext} from "../../context/UserContext";
import {Footer} from "../homePage/footer/footer";
import styles from "./myGroup.module.scss";

const axios = require("axios").default;

export const MyGroup = () => {
	const [t, i18n] = useTranslation("common");
	const {selectedPaths, basicDetails} = useUserContext();
	const navigate = useNavigate();

	const [joined, setJoined] = useState(false);
	const [alredyJoinedGroups, setAlreadyJoinedGroups] = useState([]);
	const [availableGroups, setAvailableGroups] = useState([]);
	const [updatedList, setUpdatedList] = useState(false);
	const [notification, setNotification] = useState(0);

	useEffect(() => {
		if (!basicDetails.email && !basicDetails.phone && !basicDetails.gender) navigate("/UserDetails");
		else if (!basicDetails.email || !basicDetails.gender || !basicDetails.phone) navigate("/EditDetails");
		else if (!selectedPaths.selectedGroupsMatches.length) navigate("/");

		window.scroll({top: 0});
		document.body.style.overflowY = "auto";

		axios
			.get("/api/Groups/GetSelectedGroupsMatches")
			.then((res: any) => {
				var flag = false;
				res.data.content.groupsMatches.map((group: any) => {
					if (group.state !== "added") {
						flag = true;
						setJoined(false);
						return;
					}
				});
				if (!flag) setJoined(true);
				setNotification(res.data.content.notifications);
			})
			.catch((err: any) => console.log("error:", err));
	}, []);

	useEffect(() => {
		axios
			.get("/api/Groups/GetSelectedGroupsMatches")
			.then((res: any) => {
				console.log("GetSelectedGroupsMatches", res.data.content);
				const l1: any[] = [];
				const l2: any[] = [];
				res.data.content.groupsMatches.forEach((group: any) => {
					group.state === "added" ? l2.push(group) : l1.push(group);
				});
				setL1(l1);
				setL2(l2);
				console.log("lists: ", l1, l2);
			})
			.catch((err: any) => console.log("error:", err));
	}, [updatedList]);

	const setL1 = (l1: any) => {
		setAvailableGroups(l1);
	};

	const setL2 = (l2: any) => {
		setAlreadyJoinedGroups(l2);
	};

	const handleClick = () => {
		setJoined(true);
		if (notification !== 0) {
			setNotification(0);
			axios.get("/api/Groups/ResetJoinNotification").catch((err: any) => console.log("error:", err));
		}
	};

	return (
		<PageBackground page="group" mobileBackground={"url(/assets/inner_groups/m-bg-groups.svg)"} desktopBackground={"url(/assets/inner_groups/desktop-bg-groups.svg)"}>
			<div className={styles.boxContainer}>
				<div className={styles.content}>
					<div className={styles.title}>{t("myGroup.title")}</div>
					<div className={styles.btnJoin}>
						<div className={!joined ? styles.text1 : styles.text2} onClick={() => setJoined(false)}>
							{t("myGroup.text1")}
						</div>
						<div className={joined ? styles.text1 : styles.text2} onClick={handleClick}>
							{t("myGroup.text2")}
							{notification !== 0 && !joined && (
								<div className={styles.contentNotifiction}>
									<div className={styles.number}>{notification}</div>
								</div>
							)}
						</div>
					</div>

					<div className={styles.pathsContainer}>
						{joined ? (
							!alredyJoinedGroups.length ? (
								<div className={styles.emptyBox}>
									<div
										className={styles.empty}
										style={{
											backgroundImage: "url(/assets/inner_groups/empty_state_mygroups.svg)",
										}}></div>
									<div>{t("myGroup.joinedNone")}</div>
								</div>
							) : (
								alredyJoinedGroups.map((group: any) => {
									return <MyPath key={group.groupsMatchId} id={group.groupsMatchId} whatsAppInviteLink={group.whatsAppInviteLink} setUpdatedList={setUpdatedList} updatedList={updatedList} title={i18n.language === "he" ? group.descriptions : group.descriptionsAr} name={i18n.language === "he" ? group.name : group.nameAr} syllabus={group.syllabus} state={group.state} />;
								})
							)
						) : !availableGroups.length ? (
							<div className={styles.emptyBox}>
								<div
									className={styles.empty}
									style={{
										backgroundImage: "url(/assets/inner_groups/empty_states_allgroups.svg)",
									}}></div>
								<div>{t("myGroup.joinedAll")}</div>
							</div>
						) : (
							availableGroups.map((group: any) => {
								return <MyPath key={group.groupsMatchId} id={group.groupsMatchId} whatsAppInviteLink={group.whatsAppInviteLink} setUpdatedList={setUpdatedList} updatedList={updatedList} title={i18n.language === "he" ? group.descriptions : group.descriptionsAr} name={i18n.language === "he" ? group.name : group.nameAr} syllabus={group.syllabus} state={group.state} />;
							})
						)}
					</div>
					<Link to={"/PathSelection"} className={styles.change} style={{textDecoration: "none"}}>
						{t("myGroup.change")}{" "}
					</Link>
				</div>

				<div className={styles.footer}>
					<Footer />
				</div>
			</div>
		</PageBackground>
	);
};
