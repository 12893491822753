import axios from "axios";
import {useEffect} from "react";
import {Route, Routes, useNavigate} from "react-router-dom";
import {useUserContext} from "../../context/UserContext";
import {EditDetails} from "../../screens/editDetails/editDetails";
import {Home} from "../../screens/homePage/home";
import {MyGroup} from "../../screens/myGroup/myGroup";
import {PathSelection} from "../../screens/pathSelection/pathSelection";
import Timeout from "../../screens/Timeout/Timeout";
import {UserDetails} from "../../screens/userDetails/user";
import {Routing} from "./routing";

var userPersonalinfo = {
	email: "",
	phone: "",
	gender: "",
};

export const Navigation = () => {
	const {setUserInfo, setBasicDetails, setPhoneValid, userInfo} = useUserContext();
	const navigate = useNavigate();

	useEffect(() => {
		axios
			.get("/api/Students/GetStudentBasicDetails")
			.then((res: any) => {
				userPersonalinfo = res.data.content;
				// console.log(userPersonalinfo, "userPersonalinfo");
				setBasicDetails(userPersonalinfo);
				// console.log(userPersonalinfo.phone);
				if (userPersonalinfo.phone) {
					axios
						.post("/api/Students/isPhoneValid", userPersonalinfo.phone, {
							headers: {"Content-Type": "application/json"},
						})
						.then((res1: any) => {
							console.log(res1.data.content, "phonevalid");
							const phone = res1.data.content;
							setPhoneValid(phone);
						})
						.catch(async (err: any) => {
							console.log("error phone:", err);
							setPhoneValid("500");
							navigate("/EditDetails");
						});
				}
			})
			.catch((err: any) => console.log("error details:", err));
	}, [setBasicDetails, setPhoneValid, setUserInfo]);

	const authCheck = userInfo?.isLoggedIn && userInfo?.userType === "student";

	return (
		<Routes>
			<Route path="/" element={<Home />}>
				<Route path=":fromDashboard" element={<Home />} />
			</Route>
			<Route path="/Routing" element={<Routing />} />
			<Route path="/Navigation" element={<Navigation />} />
			<Route path="/UserDetails" element={authCheck ? <UserDetails /> : <Home />} />
			<Route path="/EditDetails" element={authCheck ? <EditDetails /> : <Home />} />
			<Route path="/PathSelection/*" element={authCheck ? <PathSelection /> : <Home />} />
			<Route path="/MyGroup" element={authCheck ? <MyGroup /> : <Home />} />
			<Route path="/Timeout" element={<Timeout />} />
		</Routes>
	);
};
