
export const Login = () => {
    // console.log('accessmanagement:', (window as any).cet.accessmanagement);
    // return (window as any).cet.accessmanagement.RedirectToMoeIdpLogin(
    //     document.location.origin + "/#/Routing"
    // );
}

export const logout = () => {
    // console.log('accessmanagement:', (window as any).cet.accessmanagement);
    // return (window as any).cet.accessmanagement.logout(
    //     document.location.origin + "/#/"
    // );
}

export const extendSession = () => {
    // return (window as any).cet.accessmanag ement.extendSessionAsync();
}