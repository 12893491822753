import styles from "./footer.module.scss";
import { useScale, DeviceType } from "../../../hooks/use-scale";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
export const Footer = () => {
  const { deviceType } = useScale();
  const getBackground = (path: string) => {
    return { backgroundImage: `url(${path})` };
  };
  const [t, i18n] = useTranslation('common');

  const goTo = (url: string) => {
    window.open(url, '_blank');
    // else window.location.href = url;
  };

  let infoLinks = [
    {
      text: t('footer.privacy'),
      url: "https://storage.cet.ac.il/support/TermsOfUseAndPrivacy.pdf",
    },
    {
      text: t('footer.accessibility'),
      url: i18n.language === 'he' ? "https://lo.cet.ac.il/player/?document=68604a47-5583-4b2d-b4c2-9591007e9c8a&language=he&&options=nobar" :
        "https://lo.cet.ac.il/player/?document=68604a47-5583-4b2d-b4c2-9591007e9c8a&language=ar&&options=nobar",
    },
    {
      text: t('section1.learn'),
      url: "https://lo.cet.ac.il/player/?document=ecc8565b-4389-415a-9d43-2aa53ba19752&language=he&options=nobar",
    },
    {
      text: t('section1.contact'),
      url: "https://wa.me/972543363987",
      iconPath: "/assets/section5_footer/whatsApp-logo.svg",
    },
  ];

  infoLinks = deviceType === DeviceType.desktop ? infoLinks : infoLinks.slice(0, 2);
  const MOEURL = "https://students.education.gov.il"
  const CETURL = i18n.language === 'he' ? "https://home.cet.ac.il" : "https://home.cet.ac.il/?lang=ar";

  return (
    <div className={styles.container}>
      <div className={styles.trainContainer}>
        <div
          className={styles.train}
          style={getBackground("/assets/section5_footer/m-rails-footer.svg")}
        ></div>
      </div>
      <div className={styles.upperLinksContainer}>
        <div
          className={styles.moeLink}
          onClick={() => goTo(MOEURL)}
          style={getBackground(t('footer.portalLogoUrl'))}
        ></div>
        <div className={styles.infoLinks}>
          {infoLinks.map((linkObject) => (
            <div className={classNames(styles.link, i18n.language === 'ar' && styles.linkAr)} key={linkObject.text}>
              <div onClick={() => goTo(linkObject.url)}>{linkObject.text}</div>
              {linkObject.iconPath !== undefined && (
                <div
                  className={styles.whatsUpIcon}
                  style={getBackground(linkObject.iconPath!)}
                ></div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.copyright}>
        {t('footer.copyright')}
        <div
          className={styles.cetLink}
          onClick={() => goTo(CETURL)}
          style={getBackground(t('footer.matachLogoUrl'))}
        ></div>
        {t('footer.cetLink')}
      </div>
    </div>
  );
};
