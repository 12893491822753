import styles from "./addPath.module.scss";
import {useTranslation} from "react-i18next";
import {useCallback, useEffect, useState} from "react";
import {RadioInput} from "../radioInput/radioInput";
import classNames from "classnames";
import {ModalContainer} from "../modalContainer/modalContainer";
import {useLocation, useNavigate} from "react-router-dom";
import {useUserContext} from "../../context/UserContext";
import axios from "axios";

const defaultObj = {
	id: -1,
	parentId: -1,
	valueId: -1,
	groupMatchId: null,
	categoryNameHe: "",
	categoryNameAr: "",
	valueNameHe: "",
	valueNameAr: "",
};

type Item = typeof defaultObj;

type Props = {
	editId?: number;
};

export const AddPath: React.FC<Props> = ({editId}) => {
	const {addPath, removePath, selectedPaths} = useUserContext();
	const [, i18n] = useTranslation("common");
	const navigate = useNavigate();
	const location = useLocation();

	const state: any = location.state;
	const level: number = state?.level ?? 0;
	const path: Item[] = state?.path ?? [];
	const selected: Item = state?.selected;

	const [availableGroups, setAvailableGroups] = useState<Item[]>([]);
	const [startTimer, setStartTimer] = useState(false);

	const list = availableGroups.filter(i => i.parentId === level);
	const title =
		path.length > 0
			? path
					.slice(0)
					.reverse()
					.map(v => (i18n.language === "he" ? v.valueNameHe : v.valueNameAr))
					.join(", ")
			: list.length > 0 && (i18n.language === "he" ? list[0].categoryNameHe : list[0].categoryNameAr);
	const title2 = path.length > 0 && list.length > 0 && (i18n.language === "he" ? list[0].categoryNameHe : list[0].categoryNameAr);

	const close = useCallback(() => {
		navigate("/PathSelection");
	}, [navigate]);

	useEffect(() => {
		return () => {
			document.body.style.overflowY = "auto";
		};
	}, []);

	useEffect(() => {
		axios.get(editId ? `/api/Groups/GetAvailableGroupsMatchesIgnore/${editId}` : "/api/Groups/GetAvailableGroupsMatches").then((res: any) => {
			const data: Item[] = res.data.content.pathSteps;
			let item = data.find(g => g.groupMatchId === "" + editId);
			setAvailableGroups(data);

			if (editId) {
				while (item?.parentId !== 0) {
					const parentId = item?.parentId;
					item = data.find(g => g.id === parentId);
				}

				navigate(location.pathname, {
					replace: true,
					state: {
						selected: item,
					},
				});
			}
		});
	}, [editId, location.pathname, navigate]);

	useEffect(() => {
		if (!startTimer) return;
		if (!selected) return;
		if (selected.groupMatchId) {
			setStartTimer(false);
			return;
		}

		const timer = setTimeout(() => {
			const path: Item[] = state?.path ?? [];
			setStartTimer(false);
			navigate(location.pathname, {
				state: {
					level: selected.id,
					path: [selected, ...path],
				},
			});
		}, 500);

		return () => {
			clearTimeout(timer);
		};
	}, [close, location.pathname, navigate, path, selected, startTimer, state]);

	const goBack = () => {
		setStartTimer(false);
		navigate(-1);
	};

	const save = async () => {
		if (!selected || selected.groupMatchId === null) {
			return;
		}
		const path: Item[] = state?.path ?? [];
		const valuesSelected = [selected.valueId, ...path.map(i => i.valueId)].reverse();
		const data = {
			groupsMatchId: selected.groupMatchId,
			valuesSelected: valuesSelected,
		};
		console.log("value id array", valuesSelected);

		if (!editId) {
			addPath(data);
			close();
			return;
		}
		const find = selectedPaths.selectedGroupsMatches.find(g => g.groupsMatchId === editId);
		if (find.pathSelected !== valuesSelected.join()) {
			await removePath(find);
			await addPath(data);
		}
		close();
		return;
	};

	return (
		<ModalContainer showModal={true} path={true}>
			<div className={styles.menuContainer}>
				<div className={styles.close} style={{backgroundImage: "url(/assets/section1-top/close.svg)"}} onClick={close} />
				<div className={classNames(styles.train, path.length > 0 && styles.stage2, selected && selected.groupMatchId && styles.stage3)} style={{backgroundImage: "url(/assets/modal_bottom_sheet/rails_mikcoa.svg)"}} />

				<div className={styles.background} style={{backgroundImage: "url(/assets/inner_groups/pop-up-bg.svg)"}} />

				{level !== 0 && <div className={styles.back} onClick={goBack} style={{backgroundImage: "url(/assets/modal_bottom_sheet/back.svg)"}} />}

				<div className={styles.textBox}>
					<div className={styles.title}>{title}</div>
					{level !== 0 && <div className={styles.title2}>{title2}</div>}
				</div>

				<div className={styles.scrollContainer}>
					<div className={styles.optionBox}>
						{list.map((item: any) => (
							<div className={styles.option} key={item.id}>
								<RadioInput
									value={item.id}
									label={i18n.language === "he" ? item.valueNameHe : item.valueNameAr}
									isChecked={selected?.id === item.id}
									handleChange={() => {
										setStartTimer(true);
										navigate(location.pathname, {
											replace: true,
											state: {
												...state,
												selected: item,
											},
										});
									}}
									check={true}
									path={true}
								/>
							</div>
						))}
						<div className={classNames(list.length > 5 ? styles.fadeScroll : styles.mobileMargin)}></div>
					</div>

					<div className={styles.btn}>
						<div className={classNames(styles.edit, selected && selected.groupMatchId !== null && styles.reveal)} onClick={save}>
							<div className={styles.check} style={{backgroundImage: "url(/assets/inner_pratim_ishiim/done-btn-icon.svg)"}}></div>
						</div>
					</div>
				</div>
			</div>
		</ModalContainer>
	);
};
