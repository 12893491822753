import styles from "./user.module.scss";
import {useState, useEffect} from "react";
import {RadioInput} from "../../components/radioInput/radioInput";
import classNames from "classnames";
import {ModalContainer} from "../../components/modalContainer/modalContainer";
import {SettingsMenu} from "../../components/settingsMenu/settingsMenue";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Loader} from "../../components/loader/loader";
import {PageBackground} from "../../components/pageBackground/pageBackground";
import {useUserContext} from "../../context/UserContext";
import {InputLabel, MenuItem, Select} from "@mui/material";

const axios = require("axios").default;

export const UserDetails = () => {
	const {userInfo, setBasicDetails, basicDetails} = useUserContext();
	const [t, i18n] = useTranslation("common");
	const navigate = useNavigate();
	const [emailError, setEmailError] = useState(false);
	const [telError, setTelError] = useState(false);
	const [selectedGender, setSelectedGender] = useState("");
	const [selectedSector, setSelectedSector] = useState("");
	const [telInput, setTelInput] = useState("");
	const [emailInput, setEmailInput] = useState("");
	const [dialPrefix, setDialPrefix] = useState("050");
	const [showPrefix, setShowPrefix] = useState(false);
	const [acceptPolicy, setAcceptPolicy] = useState(false);
	const [telErrorWhathUp, setTelErrorWhathUp] = useState(false);
	const [errorTelInUse, setErrorTelInUse] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [telErrorPrivate, setTelErrorPrivate] = useState(false);
	const [checkValue, setCheckValue] = useState(false);
	const validDetails = !emailError && !telError && acceptPolicy && selectedGender && !(basicDetails.sector == null && selectedSector == "") && emailInput && telInput;
	var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

	useEffect(() => {
		window.scroll({top: 0});
		document.body.style.overflowY = "auto";
	}, []);

	useEffect(() => {
		console.log(basicDetails);
	}, [basicDetails]);

	useEffect(() => {
		deleteErrorsTel();
	}, [dialPrefix]);

	useEffect(() => {
		if (basicDetails.email && basicDetails.gender && basicDetails.phone) navigate("/PathSelection", {state: {fromUserDetails: true}});
	}, [basicDetails]);

	useEffect(() => {
		if (basicDetails.email && basicDetails.phone && basicDetails.gender) {
			navigate("/");
		}
	}, []);

	const chackValue = () => {
		setCheckValue(true);
		const phone = `+972${dialPrefix.slice(1, 3)}${telInput}`;
		axios
			.post("/api/Students/isPhoneValid", phone, {
				headers: {"Content-Type": "application/json"},
			})
			.then((res: any) => {
				console.log("isPhoneValid:", res.data.content);
				if (res.data.content === "ok") {
					axios
						.post("/api/Students/AddEditDetails", {
							changes: {
								gender: selectedGender,
								phonenumber: phone,
								email: emailInput,
								confirmterms: acceptPolicy,
								sector: selectedSector !== "" ? selectedSector : null, //if we send null, the backend will ignore updating the sector,
							},
						})
						.then((result: any) => {
							setCheckValue(false);
							setBasicDetails({
								email: emailInput,
								phone: phone,
								gender: selectedGender,
							});
						})
						.catch((err: any) => {
							setCheckValue(false);
							console.log("error:", err);
						});
				} else if (res.data.content === "private") {
					setCheckValue(false);
					setTelErrorPrivate(true);
				} else {
					setCheckValue(false);
					setErrorTelInUse(true);
				}
			})
			.catch((err: any) => {
				console.log("error:", err);
				setCheckValue(false);
				setTelErrorWhathUp(true);
				// if (err.response.status === 500) {
				// }
			});
	};

	const validateEmail = (e: any) => {
		var email = e.target.value;
		if (pattern.test(email)) {
			setEmailError(false);
			setEmailInput(e.target.value);
		} else {
			setEmailError(true);
		}
	};

	const validateTel = (e: any) => {
		const exp = new RegExp(/^\d{7}$/);
		if (exp.test(e.target.value)) {
			setTelError(false);
			setTelInput(e.target.value);
		} else {
			setTelError(true);
		}
	};

	const handleChange = (inputValue: any) => {
		setSelectedGender(inputValue);
	};

	const handleSectorChange = (inputValue: any) => {
		setSelectedSector(inputValue);
	};

	const openModal = () => {
		setShowModal(true);
	};

	const handleDial = (val: string) => {
		setDialPrefix(val);
		setShowPrefix(false);
	};

	const deleteErrorsTel = () => {
		setTelError(false);
		setTelErrorWhathUp(false);
		setTelErrorPrivate(false);
		setErrorTelInUse(false);
	};

	const deleteErrorsEmail = () => {
		setEmailError(false);
		setTelErrorWhathUp(false);
		setTelErrorPrivate(false);
		setErrorTelInUse(false);
	};

	return (
		<PageBackground page="user" mobileBackground="url(/assets/inner_pratim_ishiim/m-bg_pratim_ishiim.svg)" desktopBackground="url(/assets/inner_pratim_ishiim/desktop_bg_pratim_ishiim.svg)">
			<div className={styles.boxContainer}>
				{showPrefix && <div className={styles.closeDropdown} onClick={() => setShowPrefix(false)}></div>}
				{showModal && <ModalContainer setShowModal={setShowModal} showModal={showModal} children={<SettingsMenu />} />}
				{checkValue && <Loader />}
				<div className={styles.txt1}>{t("userDetails.txt1")}</div>
				<div className={styles.detailsTxt}>{`${t("userDetails.name")} ${userInfo.firstName}!`}</div>
				<div className={styles.txt2}>{t("userDetails.txt2")}</div>
				<div className={classNames(styles.boxInputDetails, i18n.language === "ar" && styles.boxInputDetailsAr)}>
					<div className={styles.inputBox}>
						<div className={styles.textContainer}>
							<div className={styles.details}>{t("userDetails.email")}</div>
						</div>

						<input onFocus={deleteErrorsEmail} type="email" onBlur={validateEmail} className={classNames(styles.input, emailError && styles.error)} placeholder={t("userDetails.emailInput")} />

						<div className={styles.errorBox}>{emailError && t("userDetails.errorEmail")}</div>
					</div>
					<div className={styles.inputBox}>
						<div className={styles.textContainer}>
							<div className={styles.details}>{t("userDetails.telInput")}</div>
							<div className={styles.telInput}>{t("userDetails.telInput2")}</div>
						</div>
						<div className={styles.telContent}>
							<input type="tel" onBlur={validateTel} onFocus={deleteErrorsTel} className={classNames(styles.inputTel1, (telError || telErrorPrivate || telErrorWhathUp || errorTelInUse) && styles.error)} placeholder={t("userDetails.telHolder")} />
							<div className={styles.dash}>-</div>
							<div className={classNames(styles.select, (telError || telErrorPrivate || telErrorWhathUp || errorTelInUse) && styles.error)} onClick={() => setShowPrefix(!showPrefix)}>
								{dialPrefix}
								<div className={styles.triangle}></div>
							</div>
						</div>
						<div className={classNames(styles.dialList, !showPrefix && styles.invisible)}>
							<div className={styles.scrollBox}>
								{(t("userDetails.dialPrefix", {returnObjects: true}) as any[]).map((val, index) => (
									<div className={styles.option} key={index} onClick={() => handleDial(val)}>
										{val}
									</div>
								))}
							</div>
						</div>
						<div className={styles.errorBox}>
							{telError
								? t("userDetails.errorTelMissNum")
								: telErrorWhathUp
								? t("userDetails.errorTelWhathUp")
								: errorTelInUse
								? t("userDetails.inUse")
								: telErrorPrivate && (
										<div className={styles.privateContainer}>
											<div>
												{t("userDetails.errorTelPrivate")}{" "}
												<span onClick={openModal} className={styles.linkError}>
													{t("userDetails.link")}
												</span>
											</div>
										</div>
								  )}
						</div>
					</div>
					<div className={styles.inputBox}>
						<div className={styles.textContainer}>
							<div className={styles.details}>{t("userDetails.gender")}</div>
							<div className={styles.telInput}>{t("userDetails.gender2")}</div>
						</div>
						<div className={styles.genderContent}>
							<RadioInput value="male" label={t("userDetails.male")} isChecked={selectedGender === "male"} handleChange={handleChange} check={true} />
							<RadioInput value="female" label={t("userDetails.female")} isChecked={selectedGender === "female"} handleChange={handleChange} check={true} />
						</div>
					</div>
					{basicDetails.sector === null && (
						<div className={styles.inputBox}>
							<div className={styles.textContainer}>
								<div className={styles.details}>{t("userDetails.sector")}</div>
								<div className={styles.telInput}>{t("userDetails.sector2")}</div>
							</div>
							<div className={styles.genderContent}>
								<Select
									sx={{
										boxShadow: "none",
										borderRadius: 2,
										border: "1px solid #84e989",
										".MuiOutlinedInput-notchedOutline": {border: 0},
										"&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
											border: 0,
										},
										"&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
											border: 0,
										},
									}}
									fullWidth
									onChange={e => handleSectorChange(e.target.value)}
									value={selectedSector}>
									<MenuItem value={31}>יהודי</MenuItem>
									<MenuItem value={32}>ערבי</MenuItem>
									<MenuItem value={33}>צרקסי</MenuItem>
									<MenuItem value={35}>דרוזי</MenuItem>
									<MenuItem value={30}>בדואי</MenuItem>
								</Select>
							</div>
						</div>
					)}
					<div className={classNames(styles.acceptContent, i18n.language === "ar" && styles.acceptContentAr)} onClick={() => setAcceptPolicy(!acceptPolicy)}>
						<div className={acceptPolicy ? styles.checkboxOnclick : styles.checkboxCustom}>
							<div
								className={styles.vCheck}
								style={{
									backgroundImage: acceptPolicy ? "url(/assets/inner_pratim_ishiim/v-check.svg)" : "",
								}}></div>
						</div>
						<div className={styles.accept}>{t("userDetails.accept")}</div>
					</div>
					<div className={classNames(styles.btn, validDetails && !telErrorPrivate && !telErrorWhathUp && !errorTelInUse && styles.reveal)} onClick={() => validDetails && chackValue()}>
						<div
							className={styles.vcheck}
							style={{
								backgroundImage: "url(/assets/inner_pratim_ishiim/done-btn-icon.svg)",
							}}></div>
					</div>
				</div>
			</div>
		</PageBackground>
	);
};
