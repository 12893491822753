import styles from "./header.module.scss";
import {useEffect, useState} from "react";
import {DeviceType, useScale} from "../../hooks/use-scale";
import classNames from "classnames";
import {HomeMenu} from "../homeMenu/homeMenu";
import {Login, logout} from "../../login/loginUtils";
import {Link, Navigate, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useUserContext} from "../../context/UserContext";
import axios from "axios";

type Props = {
	userDetails?: boolean;
	SSOLink?: string | null;
};

export const Header: React.FC<Props> = ({userDetails, SSOLink}) => {
	const {userInfo, basicDetails, selectedPaths, phoneValid, getAvailablePath} = useUserContext();
	const navigate = useNavigate();
	const {deviceType} = useScale();
	const [languageOpen, setLanguageOpen] = useState(false);
	const [userMenuIsOpen, setOpenUserMenu] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [userPeronalInfoComplete, setUserPeronalInfoComplete] = useState(false);
	const [groupSelect, setGroupSelect] = useState(false);
	const [userLearningRouts, setUserLearningRouts] = useState(false);
	const [t, i18n] = useTranslation("common");
	const pathname = window.location.href.slice(window.location.href.indexOf("#/") + 2, window.location.href.length);

	useEffect(() => {
		if (userInfo.isLoggedIn) {
			console.log("basic details", basicDetails);
			if (basicDetails.email && basicDetails.phone && basicDetails.gender) {
				setUserPeronalInfoComplete(true);
				if (basicDetails.email && basicDetails.phone && basicDetails.gender && phoneValid !== "error" && phoneValid !== "private") {
					setGroupSelect(true);
					getAvailablePath();
					if (selectedPaths.selectedGroupsMatches.length > 0) {
						setUserLearningRouts(true);
					} else setUserLearningRouts(false);
				} else setGroupSelect(false);
			} else {
				setUserPeronalInfoComplete(false);
				// if (window.location.href.toLocaleLowerCase().indexOf("routing") === -1) navigate("/routing");
			}
		}
	}, [userInfo.isLoggedIn, basicDetails, selectedPaths.selectedGroupsMatches.length, phoneValid, getAvailablePath]);

	const handleLogout = () => {
		axios({
			method: "POST",
			url: "/api/users/logout",
			withCredentials: true,
		}).then(response => {
			if (response.status === 200) {
				window.localStorage.removeItem("lang");
				window.location.href = response.data.redirectLocation;
			}
		});
	};

	const toggleHamburger = () => {
		setShowModal(true);
	};

	const toggleLanguage = () => {
		setLanguageOpen(!languageOpen);
	};

	const handleClickContact = () => {
		window.open("https://wa.me/972543363987");
	};

	const handleClickLearn = () => {
		window.open("https://lo.cet.ac.il/player/?document=ecc8565b-4389-415a-9d43-2aa53ba19752&language=he&options=nobar");
	};

	const scrollDown = async () => {
		let element = document.getElementById("section2");
		element?.scrollIntoView({behavior: "smooth"});
	};

	const handleClickEnter = () => {
		Login();
	};

	const handleClickUserName = () => {
		setOpenUserMenu(true);
	};
	const closeUserMenu = () => {
		setOpenUserMenu(false);
	};

	const closeUserLng = () => {
		setLanguageOpen(false);
	};

	const header = () => {
		return (
			<div className={classNames(userDetails ? styles.headerWithLogo : styles.header)}>
				{userMenuIsOpen && <div className={styles.backdrop} onClick={closeUserMenu}></div>}
				<div className={classNames(styles.card, !userMenuIsOpen && styles.invisible)}>
					<div className={styles.menu}>
						{userInfo?.userType === "student" && !userPeronalInfoComplete && (
							<Link onClick={() => pathname === "UserDetails" && window.location.reload()} to={"/UserDetails"} className={classNames(styles.menuItem, pathname === "UserDetails" && styles.onPage)}>
								{t("header.contact")}
							</Link>
						)}
						{userInfo?.userType === "student" && userPeronalInfoComplete && (
							<Link onClick={() => pathname === "EditDetails" && window.location.reload()} to={"/EditDetails"} className={classNames(styles.menuItem, pathname === "EditDetails" && styles.onPage)}>
								{t("header.contact")}
							</Link>
						)}
						{userInfo?.userType === "student" && groupSelect && (
							<Link onClick={() => pathname === "PathSelection" && window.location.reload()} to={"/PathSelection"} className={classNames(styles.menuItem, pathname === "PathSelection" && styles.onPage)}>
								{t("header.updateLearnig")}
							</Link>
						)}
						{userInfo?.userType === "student" && userLearningRouts && (
							<Link onClick={() => pathname === "MyGroup" && window.location.reload()} to={"/MyGroup"} className={classNames(styles.menuItem, pathname === "MyGroup" && styles.onPage)}>
								{t("header.myGroup")}
							</Link>
						)}
						{userInfo?.isLoggedIn && (
							<div className={classNames(styles.menuItem, styles.boldText)} onClick={handleLogout}>
								{t("header.logout")}
							</div>
						)}
					</div>
				</div>
				<div className={styles.headerReight}>
					{!userInfo?.isLoggedIn && (
						<div style={{padding: 5}}>
							<img style={{width: "80px", height: "90px", marginTop: "30px", marginLeft: "-20px", marginRight: "20px", display: "block"}} src={process.env.PUBLIC_URL + "/assets/edp/edp-logo.png"} alt="logo" />
						</div>
					)}
					{userDetails && <Link to={"/"} className={styles.logo} style={{backgroundImage: t("header.logoUrl")}}></Link>}
					{!userDetails ? (
						<div className={classNames(styles.textIconAbout, i18n.language === "ar" && styles.textIconAboutAr)} onClick={() => scrollDown()}>
							{t("section1.about")}
						</div>
					) : (
						<Link
							to={{
								pathname: `/fromDashboard`,
							}}
							className={classNames(styles.textIconAbout, i18n.language === "ar" && styles.textIconAboutAr)}
							style={{textDecoration: "none"}}>
							{t("section1.about")}
						</Link>
					)}
					{/* <div className={styles.textIcon} onClick={handleClickLearn}>
						{t("section1.learn")}
					</div> */}
					<div className={classNames(styles.textIconCnotact, i18n.language === "ar" && styles.textIconCnotactAr)} onClick={handleClickContact}>
						{t("section1.contact")}
					</div>
				</div>
				<div className={styles.headerReight}>
					<div style={{display: "flex", flexDirection: "row", alignItems: "center", cursor: "pointer"}} onClick={toggleLanguage}>
						{openLanguage()}
						<div className={styles.iconlanguage} style={{backgroundImage: "url(/assets/section1-top/ic_language.svg)"}}></div>
						<div className={styles.textIconLng}>{i18n.language === "he" ? t("section1.languageHe") : t("section1.languageArab")}</div>
						<div className={classNames(languageOpen ? styles.openLng : styles.arrowDown, userDetails && styles.moreLeft)}></div>
					</div>
					<div className={styles.icon} style={{backgroundImage: "url(/assets/section1-top/ic_user.svg)"}}></div>
					{userInfo?.isLoggedIn ? (
						<div className={styles.textIconName} onClick={handleClickUserName}>
							<span className={styles.firstName}>{userInfo?.firstName + " " + userInfo?.lastName}</span>
							<span className={classNames(userMenuIsOpen ? styles.openLng : styles.arrowDown, userDetails && styles.moreLeft)}></span>
						</div>
					) : (
						<div onClick={e => (!userInfo.isLoggedIn && SSOLink ? (window.location.href = SSOLink) : null)} className={styles.textIconEnter}>
							{t("section1.enter")}
						</div>
					)}
				</div>
			</div>
		);
	};
	const headerMobile = () => {
		return (
			<div className={classNames(userDetails ? styles.headerWithLogo : styles.headerMobile)}>
				<div className={userDetails ? styles.contentLogo : ""}>
					<div className={classNames(styles.burger, userDetails && styles.headerUser)} style={{backgroundImage: "url(/assets/section1-top/ic_hamburger.svg)"}} onClick={toggleHamburger}></div>
					{userDetails && <Link to={"/"} className={styles.logo} style={{backgroundImage: t("header.logoUrl")}}></Link>}
				</div>
				<div className={classNames(styles.langMobile, userDetails && styles.headerUser)} style={{backgroundImage: "url(/assets/section1-top/ic_language.svg)"}} onClick={toggleLanguage}>
					{openLanguage()}
				</div>
			</div>
		);
	};
	const openLanguage = () => {
		return (
			<div className={classNames(styles.cardLng, !languageOpen && styles.invisible)}>
				<div className={styles.languageMenu}>
					{i18n.language === "he" ? (
						<div
							className={styles.optionLng}
							onClick={() => {
								window.localStorage.setItem("lang", "ar");
								i18n.changeLanguage("ar");
							}}>
							{t("section1.languageArab")}
						</div>
					) : (
						<div
							className={styles.optionLng}
							onClick={() => {
								window.localStorage.setItem("lang", "he");
								i18n.changeLanguage("he");
							}}>
							{t("section1.languageHe")}
						</div>
					)}
				</div>
			</div>
		);
	};
	return (
		<div className={classNames(styles.container, userDetails && styles.userDetails)}>
			{languageOpen && <div className={styles.backdrop} onClick={closeUserLng}></div>}
			{showModal && <HomeMenu userDetails={userDetails} setShowModal={setShowModal} showModal={showModal} userPeronalInfoComplete={userPeronalInfoComplete} groupSelect={groupSelect} userLearningRouts={userLearningRouts} login={userInfo} />}
			{deviceType === DeviceType.mobile ? headerMobile() : header()}
		</div>
	);
};
