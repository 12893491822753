import styles from "./pathSelection.module.scss";
import classNames from "classnames";
import {useEffect} from "react";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {PageBackground} from "../../components/pageBackground/pageBackground";
import {useTranslation} from "react-i18next";
import {PathCard} from "../../components/pathCard/pathCard";
import {AddPath} from "../../components/addPath/addPath";
import {useUserContext} from "../../context/UserContext";
import {Footer} from "../homePage/footer/footer";
import {DeviceType, useScale} from "../../hooks/use-scale";

export const PathSelection = () => {
	const {deviceType} = useScale();
	const {selectedPaths, getAvailablePath, basicDetails} = useUserContext();
	const existingGroups = selectedPaths.selectedGroupsMatches;
	const numOfAvailableGroups = selectedPaths.slotCount;
	const [t, i18n] = useTranslation("common");

	const navigate = useNavigate();
	const fromUserDetails = (useLocation().state as any)?.fromUserDetails;

	useEffect(() => {
		if (!basicDetails.email || !basicDetails.phone || !basicDetails.gender) {
			navigate("/EditDetails");
		}
		if (!basicDetails.email && !basicDetails.phone && !basicDetails.gender) {
			navigate("/UserDetails");
		}
	}, []);

	useEffect(() => {
		console.log("existingGroups", existingGroups);

		window.scroll({top: 0});
		document.body.style.overflowY = "auto";
		getAvailablePath();
	}, [getAvailablePath]);

	const handleShowPaths = () => {
		navigate("/PathSelection/add");
	};

	return (
		<PageBackground page="path" mobileBackground={"url(/assets/inner_maslul/m-bg-bhirat_maslul.svg)"} desktopBackground={"url(/assets/inner_maslul/desktop-bg-bhirat_maslul.svg)"}>
			<div className={styles.boxContainer}>
				<div className={styles.content}>
					<Routes>
						<Route path="/add" element={<AddPath />} />
					</Routes>
					<div className={styles.title}>{t("pathSelection.title")}</div>
					<div className={styles.body}>{fromUserDetails ? t("pathSelection.text1.1") : existingGroups.length ? t("pathSelection.text3") : t("pathSelection.text2")}</div>
					{fromUserDetails && <div className={styles.body}>{t("pathSelection.text1.2")}</div>}

					<div className={styles.instructionContainer}>
						<div className={styles.point} style={{backgroundImage: "url(/assets/inner_maslul/group-3-point.svg)"}}></div>
						<div className={classNames(styles.instruction, i18n.language === "ar" && styles.instructionAr)}>{`${t("pathSelection.inst1")} ${numOfAvailableGroups} ${t("pathSelection.inst2")}`}</div>
					</div>

					<div className={styles.pathsContainer}>
						{existingGroups.map((group: any) => {
							const str = i18n.language === "he"?  group.descriptions.replace(/,/g, ", ") : group.descriptionsAr.replace(/,/g, ", ");
							var uniqueKey = group.pathSelected.split(",").reduce((previousValue: any, currentValue: any) => previousValue + currentValue);
							return <PathCard key={uniqueKey} groupMatchId={group.groupsMatchId} name={i18n.language === "he"? group.name : group.nameAr} syllabus={str} uniqueKey={uniqueKey} />;
						})}
						{/*TODO: This 4 represents how many groups each student can join at the same time, move this number to the projects table and fetch it*/}
						{existingGroups.length < 4 && numOfAvailableGroups - existingGroups.length > 0 && (
							<div className={styles.addPath} onClick={handleShowPaths}>
								<div className={styles.dotImg} style={{backgroundImage: "url(/assets/inner_maslul/m-dots_bg_add.svg)"}}>
									<div className={styles.addImg} style={{backgroundImage: "url(/assets/inner_maslul/add.svg)"}}></div>
									<div className={styles.cardTxt}>{t("pathSelection.createPath")}</div>
								</div>
							</div>
						)}
					</div>

					{existingGroups.length > 0 && <p className={classNames(styles.whatsAppButtonHelp)}>{t("pathSelection.whatsAppJoinGroupHelpMessage")}</p>}
					<div className={classNames(styles.whatsAppButton, existingGroups.length > 0 && styles.reveal)} onClick={() => (existingGroups.length > 0 ? navigate("/MyGroup") : {})}>
						{i18n.language === "he" && <div className={styles.whatsAppIcon} style={{backgroundImage: "url(/assets/inner_maslul/icon-whatsapp.svg)"}}></div>}
						<div className={classNames(styles.whatsAppGroup, i18n.language === "ar" && styles.whatsAppGroupAr)}>{t("pathSelection.whatsAppGroup")}</div>
					</div>
				</div>
				{deviceType !== DeviceType.mobile && (
					<div className={styles.footer}>
						<Footer />
					</div>
				)}
			</div>
		</PageBackground>
	);
};
