import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import dataHe from "./utils/dataHe.json";
import dataAr from "./utils/dataAr.json";
import {HashRouter} from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import axios from "axios";

const lng = window.localStorage.getItem("lang") ?? "he";
i18next.init({
	interpolation: {escapeValue: false},
	lng,
	resources: {
		he: {
			common: dataHe,
		},
		ar: {
			common: dataAr,
		},
	},
});

ReactDOM.render(
	<HashRouter>
		<I18nextProvider i18n={i18next}>
			<App />
		</I18nextProvider>
	</HashRouter>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
