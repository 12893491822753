import classNames from "classnames";
import styles from "./radioInput.module.scss";

type Props = {
    value: string,
    label: any,
    isChecked: boolean,
    handleChange: any,
    check?: boolean,
    path?: boolean
}
export const RadioInput: React.FC<Props> = ({ label, value, isChecked, handleChange, check, path }) => {
    const handleRadioChange = (e: any) => {
        handleChange(value);
    };

    return (
        < div className={classNames(styles.checkboxContent, path && styles.path, isChecked && styles.check, !isChecked && !check && styles.disableBox)} onClick={handleRadioChange} >
            < div className={classNames(styles.checkbox, path && styles.check2, isChecked && styles.checkboxClick, !check && styles.btnCheck, !check && styles.disable)}>
                <div className={classNames(isChecked && styles.circel, path && styles.circel2, !check && styles.btnCheck, !isChecked && check && styles.transparent)} />
            </div>
            <span>{label}</span>
        </div >
    );
};