import styles from "./editDetails.module.scss";
import {DeviceType, useScale} from "../../hooks/use-scale";
import {useState, useEffect} from "react";
import {RadioInput} from "../../components/radioInput/radioInput";
import classNames from "classnames";
import {useNavigate} from "react-router-dom";
import {PageBackground} from "../../components/pageBackground/pageBackground";
import {useTranslation} from "react-i18next";
import {ModalContainer} from "../../components/modalContainer/modalContainer";
import {WarningPopUp} from "../../components/warningPopUp/warningPopUp";
import {Loader} from "../../components/loader/loader";
import {SettingsMenu} from "../../components/settingsMenu/settingsMenue";
import {useUserContext} from "../../context/UserContext";

const axios = require("axios").default;

export const EditDetails = () => {
	const {phoneValid, setPhoneValid, userInfo, basicDetails, setBasicDetails} = useUserContext();
	const [t, i18n] = useTranslation("common");
	const navigate = useNavigate();
	const [emailError, setEmailError] = useState(false);
	const [telError, setTelError] = useState(false);
	const [selectedGender, setSelectedGender] = useState(basicDetails.gender);
	const [telInput, setTelInput] = useState("");
	const [emailInput, setEmailInput] = useState(basicDetails.email);
	const [editGender, setEditGender] = useState(false);
	const [dialPrefix, setDialPrefix] = useState("");
	const [showPrefix, setShowPrefix] = useState(false);
	const [editTel, setEditTel] = useState(false);
	const [tel1, setTel1] = useState("");
	const [editEmail, setEditEmail] = useState(false);
	const {deviceType} = useScale();
	const [checkValue, setCheckValue] = useState(false);
	const [telErrorWhathUp, setTelErrorWhathUp] = useState(false);
	const [errorTelInUse, setErrorTelInUse] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [telErrorPrivate, setTelErrorPrivate] = useState(false);
	const [warning, setWarning] = useState("");
	const [update, setUpdate] = useState(false);
	const [popUp, setPopUp] = useState(false);
	const checkTel = !telError && !telErrorPrivate && !telErrorWhathUp && !errorTelInUse;
	const vcheck = (editEmail && !emailError) || (editGender && selectedGender) || (editTel && checkTel);
	var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

	useEffect(() => {
		console.log(update, "update");
		axios
			.get("/api/Students/GetStudentBasicDetails")
			.then((res: any) => {
				setBasicDetails(res.data.content);
				console.log(update, res, "update");
				let phone = `0${res.data.content.phone.slice(4, 6)}-${res.data.content.phone.slice(6, 13)}`;
				console.log("data:", res.data.content);
				setTelInput(res.data.content.phone && phone);
				setTel1(res.data.content.phone.slice(6, 13));
				setDialPrefix(`0${res.data.content.phone.slice(4, 6)}`);
				setSelectedGender(res.data.content.gender);
				setEmailInput(res.data.content.email);
			})
			.catch((err: any) => console.log("error:", err));
	}, [update]);

	useEffect(() => {
		if (dialPrefix.length > 3) deleteErrorsTel();
	}, [dialPrefix]);

	useEffect(() => {
		document.body.style.overflowY = "auto";
		window.scroll({top: 0});
		console.log(emailInput, "emailInput");
	}, []);

	useEffect(() => {
		if (phoneValid === "500") {
			setEditTel(true);
			setTelErrorWhathUp(true);
		}
	}, [phoneValid]);

	const handleDial = (val: string) => {
		setDialPrefix(val);
		setShowPrefix(false);
	};

	const chackValue = () => {
		const phone = `+972${dialPrefix.slice(1, 3)}${tel1}`;
		setTelInput(`0${dialPrefix.slice(1, 3)}-${tel1}`);
		if (phone !== basicDetails.phone) {
			setCheckValue(true);
			axios
				.post("/api/Students/isPhoneValid", phone, {headers: {"Content-Type": "application/json"}})
				.then((res: any) => {
					console.log("data:", res?.data?.content);
					if (res?.data?.content === "ok") {
						setPhoneValid(res?.data?.content);
						axios
							.post("/api/Students/AddEditDetails", {
								changes: {
									phonenumber: phone,
								},
							})
							.then((result: any) => {
								setUpdate(!update);
								console.log(result);
								setEditTel(false);
								navigate("/PathSelection", {state: true});
							})
							.catch((err: any) => console.log("error:", err));
					} else if (res?.data?.content === "private") {
						setTelErrorPrivate(true);
						setCheckValue(false);
					} else {
						setTelErrorWhathUp(true);
						setCheckValue(false);
					}
				})
				.catch((err: any) => {
					if (err.response.status === 500) {
						setTelErrorWhathUp(true);
						setCheckValue(false);
					}
				});
		} else {
			setEditTel(false);
		}
	};

	const sendEmail = () => {
		console.log(emailInput, "emailInput");
		if (!emailInput) {
			setEmailError(true);
		} else if (!emailError) {
			setEditEmail(false);
			if (emailInput === basicDetails.email) return;

			axios
				.post("/api/Students/AddEditDetails", {
					changes: {
						email: emailInput,
					},
				})
				.then(() => {
					setUpdate(!update);
				})
				.catch((err: any) => console.log("error:", err));
		}
	};

	const sendTel = () => {
		if (!telErrorPrivate && !telError && !telErrorWhathUp && !errorTelInUse) {
			// setEditTel(false);
			chackValue();
		}
	};

	const validateEmail = (e: any) => {
		var email = e.target.value;
		if (pattern.test(email)) {
			setEmailError(false);
			setEmailInput(e.target.value);
		} else {
			setEmailError(true);
		}
	};

	const validateTel = (e: any) => {
		const exp = new RegExp(/^\d{7}$/);
		if (exp.test(e.target.value)) {
			console.log(e.target.value, "e.target.value");
			setTel1(e.target.value);
			setTelError(false);
		} else {
			setTelError(true);
		}
	};

	const handleChange = (inputValue: any) => {
		setSelectedGender(inputValue);
	};

	const openModal = () => {
		setShowModal(true);
	};

	const sendGender = () => {
		setEditGender(false);
		if (selectedGender === basicDetails.gender) return;
		axios
			.post("/api/Students/AddEditDetails", {
				changes: {
					gender: selectedGender,
				},
			})
			.then(() => setUpdate(!update))
			.catch((err: any) => console.log("error:", err));
	};

	const deleteErrorsTel = () => {
		setTelError(false);
		setTelErrorWhathUp(false);
		setTelErrorPrivate(false);
		setErrorTelInUse(false);
	};

	const deleteErrorsEmail = () => {
		setEmailError(false);
		setTelErrorWhathUp(false);
		setTelErrorPrivate(false);
		setErrorTelInUse(false);
	};

	const modal = () => {
		if (popUp) {
			return <WarningPopUp type={warning} edit={warning === "tel" ? setEditTel : setEditGender} setShowModal={setPopUp} />;
		} else if (showModal) {
			return <ModalContainer setShowModal={setShowModal} showModal={showModal} children={<SettingsMenu />} />;
		} else return <div></div>;
	};
	const sendDetails = () => {
		if (editEmail) {
			sendEmail();
		} else if (editTel) {
			sendTel();
		} else sendGender();
	};

	const toolTip = (type: string) => {
		if (deviceType === DeviceType.mobile) return;
		return (
			<div className={classNames(styles.tooltip, type === "save" && styles.toolOne)}>
				{type === "edit" ? t("userDetails.edit") : t("userDetails.save")}
				<div className={styles.triangle}></div>
			</div>
		);
	};

	return (
		<PageBackground page="edit" mobileBackground={"url(/assets/inner_pratim_ishiim/m-bg_pratim_ishiim.svg)"} desktopBackground={"url(/assets/inner_pratim_ishiim/desktop_bg_pratim_ishiim.svg)"}>
			{modal()}
			<div className={styles.boxContainer}>
				{showPrefix && <div className={styles.closeDropdown} onClick={() => setShowPrefix(false)}></div>}
				{checkValue && <Loader />}
				<div className={styles.txt1}>{t("header.contact")}</div>
				<div className={styles.detailsTxt}>{`${userInfo.firstName} ${userInfo.lastName}`}</div>
				<div className={classNames(styles.boxInputDetails, i18n.language === "ar" && styles.boxInputDetailsAr)}>
					<div className={styles.boxdeskEmail}>
						<div className={classNames(styles.details, styles.textContainer)}>{t("userDetails.email")}</div>
						<input onFocus={deleteErrorsEmail} type="email" className={classNames(styles.input, !editEmail && styles.inputLock, emailError && styles.error)} defaultValue={emailInput} disabled={!editEmail} onBlur={validateEmail} />
						{emailError && <div className={classNames(styles.errorEmail, i18n.language === "ar" && styles.arabEmail)}>{t("userDetails.errorEmail")}</div>}
						{editEmail ? (
							deviceType !== DeviceType.mobile && (
								<div className={classNames(styles.done, emailError && styles.fade)} onClick={sendEmail}>
									<div className={styles.v} style={{backgroundImage: "url(/assets/inner_pratim_ishiim/done-btn-icon.svg)"}}></div>
									{toolTip("save")}
								</div>
							)
						) : (
							<div className={classNames(styles.edit, (editTel || editGender) && styles.fade)} onClick={!editTel && !editGender ? () => setEditEmail(true) : () => {}} style={{backgroundImage: "url(/assets/inner_pratim_ishiim/edit_icon.svg)"}}>
								{toolTip("edit")}
							</div>
						)}
					</div>
					<div className={styles.boxdeskTel}>
						<div className={styles.textContainer}>
							<div className={styles.details}>{t("userDetails.telInput")}</div>
							<div className={styles.telInput}>{t("userDetails.telInput2")}</div>
						</div>
						{!editTel ? (
							<input onFocus={deleteErrorsTel} type="tel" disabled={!editTel} onBlur={validateTel} className={classNames(styles.input, !editTel && styles.inputLock, telError && styles.error)} defaultValue={telInput} />
						) : (
							<div>
								<div className={styles.telContent}>
									<input type="tel" onFocus={deleteErrorsTel} onBlur={validateTel} onChange={e => setTelInput(e.target.value)} className={classNames(styles.inputTel1, (telError || telErrorPrivate || telErrorWhathUp || errorTelInUse) && styles.error)} defaultValue={tel1} />
									<div>-</div>
									<div
										className={classNames(styles.select, (telError || telErrorPrivate || telErrorWhathUp || errorTelInUse) && styles.error)}
										onClick={() => {
											setShowPrefix(!showPrefix);
										}}>
										{dialPrefix}
										<div className={styles.triangleDial}></div>
									</div>
								</div>
								<div className={classNames(styles.dialList, !showPrefix && styles.invisible)}>
									<div className={styles.scrollBox}>
										{(t("userDetails.dialPrefix", {returnObjects: true}) as any[]).map((val, index) => (
											<div className={styles.option} key={index} onClick={() => handleDial(val)}>
												{val}
											</div>
										))}
									</div>
								</div>
							</div>
						)}
						{editTel ? (
							deviceType !== DeviceType.mobile && (
								<div className={classNames(styles.done, (telErrorPrivate || telError || telErrorWhathUp || errorTelInUse) && styles.fade)} onClick={sendTel}>
									<div className={styles.v} style={{backgroundImage: "url(/assets/inner_pratim_ishiim/done-btn-icon.svg)"}}></div>
									{toolTip("save")}
								</div>
							)
						) : (
							<div
								className={classNames(styles.edit, (editEmail || editGender) && styles.fade)}
								onClick={
									!editEmail && !editGender
										? () => {
												setWarning("tel");
												setPopUp(true);
										  }
										: () => {}
								}
								style={{backgroundImage: "url(/assets/inner_pratim_ishiim/edit_icon.svg)"}}>
								{toolTip("edit")}
							</div>
						)}
						<div className={styles.errorTel}>
							{telError
								? t("userDetails.errorTelMissNum")
								: telErrorWhathUp
								? t("userDetails.errorTelWhathUp")
								: errorTelInUse
								? t("userDetails.inUse")
								: telErrorPrivate && (
										<div className={styles.privateContainer}>
											<div>
												{t("userDetails.errorTelPrivate")}{" "}
												<span onClick={openModal} className={styles.linkError}>
													{t("userDetails.link")}
												</span>
											</div>
										</div>
								  )}
						</div>
					</div>
					<div className={styles.boxdeskTel}>
						<div className={styles.textContainer}>
							<div className={styles.details}>{t("userDetails.gender")}</div>
							<div className={styles.telInput}>{t("userDetails.gender2")}</div>
						</div>
						<div className={styles.genderContent}>
							{["male", "female"].map((gender, index) => {
								const check = !editGender && ((gender === "male" && selectedGender !== "male") || (gender === "female" && selectedGender !== "female"));
								return (
									<div className={classNames(!editGender && styles.cursor, check ? styles.btnLock : "")} key={index}>
										<RadioInput value={gender} label={gender === "male" ? t("userDetails.male") : t("userDetails.female")} isChecked={(gender === "male" && selectedGender === "male") || (gender === "female" && selectedGender === "female")} handleChange={editGender ? handleChange : () => {}} check={editGender} />
									</div>
								);
							})}
						</div>
						{editGender ? (
							deviceType !== DeviceType.mobile && (
								<div className={styles.done} onClick={sendGender}>
									<div className={styles.v} style={{backgroundImage: "url(/assets/inner_pratim_ishiim/done-btn-icon.svg)"}}></div>
									{toolTip("save")}
								</div>
							)
						) : (
							<div
								className={classNames(styles.edit, (editEmail || editTel) && styles.fade)}
								onClick={
									!editEmail && !editTel
										? () => {
												setWarning("gender");
												setPopUp(true);
										  }
										: () => {}
								}
								style={{backgroundImage: "url(/assets/inner_pratim_ishiim/edit_icon.svg)"}}>
								{toolTip("edit")}
							</div>
						)}
					</div>
					{deviceType === DeviceType.mobile && (editGender || editTel || editEmail) && (
						<>
							<div className={classNames(styles.btn, vcheck && styles.reveal)} onClick={sendDetails}>
								<div className={styles.vcheck} style={{backgroundImage: "url(/assets/inner_pratim_ishiim/done-btn-icon.svg)"}}></div>
							</div>
							<div className={styles.fadeScroll}></div>
						</>
					)}
				</div>
			</div>
		</PageBackground>
	);
};
