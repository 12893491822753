import React, {useEffect, useRef} from "react";
import lottie from "lottie-web";
import animation from "../../timeout.json";

export default function Timeout() {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const container: any = useRef(null);

	// eslint-disable-next-line react-hooks/rules-of-hooks
	useEffect(() => {
		lottie.loadAnimation({
			container: container.current,
			renderer: "svg",
			loop: true,
			autoplay: true,
			animationData: animation,
		});

		setTimeout(() => {
			window.location.href = "/";
		}, 1000);
	}, []);

	return (
		<div style={{height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#F8FCF8"}}>
			<div ref={container} style={{backgroundRepeat: "no-repeat"}}></div>
		</div>
	);
}
