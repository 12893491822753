import classNames from "classnames";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Login, logout} from "../../login/loginUtils";
import DottedDevider from "../dottedDevider/dottedDevider";
import {ModalContainer} from "../modalContainer/modalContainer";
import styles from "./homeMenu.module.scss";
import {Transition, CSSTransition} from "react-transition-group";
import {useEffect} from "react";

type Props = {
	setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
	showModal: boolean;
	userDetails?: boolean;
	userPeronalInfoComplete: boolean;
	groupSelect: boolean;
	userLearningRouts: boolean;
	login: any;
};

export const HomeMenu: React.FC<Props> = ({setShowModal, showModal, userDetails, userPeronalInfoComplete, groupSelect, userLearningRouts, login}) => {
	const [t] = useTranslation("common");
	const pathname = window.location.href.slice(window.location.href.indexOf("#/") + 2, window.location.href.length);

	useEffect(() => {
		return () => {};
	}, []);

	const handleClickContact = () => {
		window.open("https://wa.me/972543363987");
	};

	const handleClickLearn = () => {
		window.open("https://lo.cet.ac.il/player/?document=ecc8565b-4389-415a-9d43-2aa53ba19752&language=he&options=nobar");
	};
	const scrollDown = () => {
		let element = document.getElementById("section2");
		element?.scrollIntoView({behavior: "smooth"});
		document.body.style.overflowY = "auto";
	};

	const logoutUser = () => {
		window.localStorage.removeItem("lang");
		logout();
	};

	const closeModal = (event: any) => {
		event.stopPropagation();
	};
	return (
		// <CSSTransition in={true} timeout={400} classNames={styles.alert} unmountOnExit>
		<ModalContainer setShowModal={setShowModal} showModal={showModal} modalSide={true}>
			<div className={styles.burgerMenu} onClick={closeModal}>
				<div className={styles.contentWrapper}>
					<div className={styles.menuHeader}>
						<div style={{marginBottom: 25, marginRight: 30}}>
							<img style={{width: "55%", height: "90%"}} src={process.env.PUBLIC_URL + "/assets/edp/edp-logo.png"} alt="logo" />
						</div>
						{!userDetails ? (
							<div
								className={styles.txtHamburger}
								onClick={() => {
									setShowModal(!showModal);
									scrollDown();
								}}>
								{t("section1.about")}
							</div>
						) : (
							<Link
								to={{
									pathname: `/fromDashboard`,
								}}
								style={{textDecoration: "none"}}
								className={styles.txtHamburger}>
								{t("section1.about")}
							</Link>
						)}
						<div className={styles.txtHamburger} onClick={handleClickLearn}>
							{t("section1.learn")}
						</div>
						<div className={styles.txtHamburger} onClick={handleClickContact}>
							{t("section1.contact")}
						</div>
					</div>
					<DottedDevider orientation="horizontal" />
					<div className={styles.menu}>
						<div className={styles.enterBox}>
							<div className={styles.iconM} style={{backgroundImage: "url(/assets/section1-top/ic_user.svg)"}}></div>
							{login?.isLoggedIn ? (
								<div className={styles.textFirst}>{`${login?.firstName} ${login?.lastName}`}</div>
							) : (
								<div className={styles.textM} onClick={Login}>
									{t("section1.enter")}
								</div>
							)}
						</div>
						<div className={styles.contentDetails}>
							{login?.userType === "student" && !userPeronalInfoComplete && (
								<Link
									onClick={() => {
										pathname === "UserDetails" && window.location.reload();
									}}
									to={"/UserDetails"}
									className={classNames(styles.menuItem, pathname === "UserDetails" && styles.onPage)}>
									{t("header.contact")}
								</Link>
							)}
							{login?.userType === "student" && userPeronalInfoComplete && (
								<Link
									onClick={() => {
										pathname === "EditDetails" && window.location.reload();
									}}
									to={"/EditDetails"}
									className={classNames(styles.menuItem, pathname === "EditDetails" && styles.onPage)}>
									{t("header.contact")}
								</Link>
							)}
							{login?.userType === "student" && groupSelect && (
								<Link
									onClick={() => {
										pathname === "PathSelection" && window.location.reload();
									}}
									to={"/PathSelection"}
									className={classNames(styles.menuItem, pathname === "PathSelection" && styles.onPage)}>
									{t("header.updateLearnig")}
								</Link>
							)}
							{login?.userType === "student" && userLearningRouts && (
								<Link
									onClick={() => {
										pathname === "MyGroup" && window.location.reload();
									}}
									to={"/MyGroup"}
									className={classNames(styles.menuItem, pathname === "MyGroup" && styles.onPage)}>
									{t("header.myGroup")}
								</Link>
							)}
							{login?.isLoggedIn && (
								<div className={classNames(styles.menuItem, styles.boldText)} onClick={logoutUser}>
									{t("header.logout")}
								</div>
							)}
						</div>
					</div>
				</div>
				<div className={styles.bg_menu} style={{backgroundImage: "url(/assets/section1-top/bg_menu.svg)"}}></div>
			</div>
		</ModalContainer>
		// </CSSTransition>
	);
};
