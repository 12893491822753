import { useState } from 'react';
import styles from './pathCard.module.scss';
import { useTranslation } from 'react-i18next';
import { WarningPopUp } from '../warningPopUp/warningPopUp';
import { AddPath } from '../addPath/addPath';
import axios from 'axios';
import { LeaveGroup } from '../leaveGroup/leaveGroup';
import { useUserContext } from '../../context/UserContext';
import { Route, Routes, useNavigate } from 'react-router-dom';

type Props = {
    name: string,
    syllabus: string,
    groupMatchId: number,
    uniqueKey: string
}

export const PathCard: React.FC<Props> = ({ name, syllabus, groupMatchId, uniqueKey }) => {
    const [t] = useTranslation('common');
    const navigate = useNavigate();

    const { removePath } = useUserContext();
    const [showOptions, setShowOptions] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [check, setCheck] = useState('');
    const [choice, setChoice] = useState('');
    const [leaveGroup, setLeaveGroup] = useState(false)

    const moreOptions = () => {
        console.log('group id', groupMatchId);
        setShowOptions(!showOptions);
    }

    const handleChoice = (x: string) => {
        console.log(x);
        setShowOptions(false);
        axios.get('/api/Groups/GetSelectedGroupsMatches')
            .then((res: any) => {
                const item = res.data.content.groupsMatches.find((group: any) => group.groupsMatchId === groupMatchId);
                console.log('selected',res.data.content.groupsMatches);
                
                setChoice(x);
                if (x === 'a') {
                    console.log('name check', x);
                    setCheck(item.name);
                    if (item.state === 'added') {
                        setShowModal(true);
                    }
                    else {
                        openEdit();
                    }
                }
                else {
                    if (item.state === 'added') {
                        setShowModal(true);
                    }
                    else removePath(item);
                }
            })
            .catch((error: any) => console.log(error))
    }

    const openEdit= () =>{
        navigate("/PathSelection/edit/" + uniqueKey);
    }

    return (
        <div className={styles.card}>
            {showOptions && <div className={styles.close} onClick={moreOptions}></div>}
            {showModal && <WarningPopUp edit={openEdit} setShowModal={setShowModal} groupMatchId={groupMatchId} type={choice} check={check} setCheck={setCheck} setLeaveGroup={setLeaveGroup} joined={'added'} />}
            {leaveGroup && <LeaveGroup setShowModal={setLeaveGroup} />}
            <Routes>
                <Route path={"/edit/"+ uniqueKey}
                    element={<AddPath editId={groupMatchId} />} />
            </Routes>
            <div className={styles.content}>
                <div className={styles.dots} onClick={moreOptions}>
                    <div className={styles.dotsIcon} style={{ backgroundImage: showOptions ? 'url(/assets/inner_maslul/dots-more-selected.svg)' : 'url(/assets/inner_maslul/dots-more.svg)' }}></div>
                </div>

                <div className={styles.path}>
                    <div className={styles.pathName}>{name}</div>
                    <div className={styles.pathDescription}>{syllabus}</div>
                </div>

                {showOptions &&
                    <div className={styles.optionBox}>
                        {['a', 'b'].map((x, index) =>
                            <div className={styles.option} key={index} onClick={() => handleChoice(x)}>
                                <div className={styles.optionIcon} style={{ backgroundImage: x === 'a' ? 'url(/assets/inner_maslul/edit_icon.svg)' : 'url(/assets/inner_maslul/delete.svg)' }}></div>
                                <div className={styles.optionTxt}>{x === 'a' ? t('pathSelection.edit') : t('pathSelection.delete')}</div>
                            </div>
                        )}
                    </div>
                }
            </div>
        </div>
    )
}