import "./App.css";
import {ScaleProvided} from "./hooks/use-scale";
import {Navigation} from "./components/navigation/navigation";
import {UserContextProvider} from "./context/UserContext";
import {useEffect} from "react";
import {extendSession} from "./login/loginUtils";
import axios from "axios";
import {useLocation, useNavigate} from "react-router-dom";

function App() {
	const MINUTE_MS = 80000;
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		console.log("SAML VERSION 1 DEPLOYMENT TEST");
		const interval = setInterval(() => {
			extendSession();
			console.log("Logs every minute");
		}, MINUTE_MS);
		return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
	}, []);

	// Handle session timeouts
	axios.interceptors.response.use(
		response => {
			return response;
		},
		error => {
			if (error.response.status === 401 && location.pathname !== "/" && location.pathname !== "/routing") {
				navigate("/timeout");
			}
		}
	);

	return (
		<UserContextProvider>
			<ScaleProvided>
				<Navigation />
			</ScaleProvided>
		</UserContextProvider>
	);
}

export default App;
