import styles from "./pageBackground.module.scss";
import {DeviceType, useScale} from "../../hooks/use-scale";
import {Header} from "../../components/header/header";
import {Footer} from "../../screens/homePage/footer/footer";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import axios from "axios";

type Props = {
	children: JSX.Element | JSX.Element[];
	mobileBackground: string;
	desktopBackground: string;
	page?: string;
};

export const PageBackground: React.FC<Props> = ({children, mobileBackground, desktopBackground, page}) => {
	const {deviceType} = useScale();
	const [, i18n] = useTranslation("common");

	return (
		<div className={styles.contentContainer}>
			<Header userDetails={true} />
			<div className={styles.train} style={{backgroundImage: deviceType === DeviceType.mobile ? mobileBackground : desktopBackground}}></div>
			<div className={classNames(styles.content, page === "edit" ? (styles.edit, i18n.language === "ar" && styles.arabEdit) : page === "path" ? styles.path : page === "group" && styles.group)}>{children}</div>
			{page !== "path" && page !== "group" && (deviceType !== DeviceType.mobile || page === "edit") && (
				<div className={styles.footer}>
					<Footer />
				</div>
			)}
		</div>
	);
};
