import styles from "./leaveGroup.module.scss";
import classNames from "classnames";
import { useEffect, useState } from "react";
import axios from "axios";
import { useUserContext } from "../../context/UserContext";
import { useTranslation } from "react-i18next";

type Props = {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
    setUpdatedList?: React.Dispatch<React.SetStateAction<boolean>>,
    updatedList?: boolean,
    pathName?: string,
    groupNum?: number
}

export const LeaveGroup: React.FC<Props> = ({ setShowModal, setUpdatedList, updatedList, pathName, groupNum }) => {
    const { getAvailablePath, surveyContent } = useUserContext();
    const [t, i18n] = useTranslation('common');
    const [answer, setAnswer] = useState(0);
    const [msg, setMsg] = useState('');
    const [enable, setEnable] = useState(false);

    useEffect(() => {
        if (answer === 1 || answer === 2 || (answer === 3 && msg.length >= 2))
            setEnable(true);
        else
            setEnable(false);

    }, [answer])

    useEffect(() => {
        if (answer === 3 && msg.length >= 2)
            setEnable(true);
        else
            setEnable(false);

    }, [msg])

    useEffect(() => { 
        document.body.style.overflowY = 'hidden';
        return(() => {
            document.body.style.overflowY = 'auto';
        })
    }, [])

    const handleClick = () => {
       if(!enable) return;
        const data = {
            id: groupNum ? groupNum : surveyContent.id,
            reasonState: answer,
            message: msg
        }
        console.log(msg.length, msg);
        
        axios.post('/api/Groups/LeaveSurvey', data)
        .then((res: any) => {
            console.log(res);
            getAvailablePath();
            setUpdatedList && setUpdatedList(!updatedList);
            setShowModal(false);
        })
        .catch((error: any) => console.log(error))
    }

    return (
        <div className={styles.modalContainer}>
            <div className={styles.menuContainer}>
                <div className={styles.emoji} style={{ backgroundImage: 'url(/assets/modal_hodaot/modal_seker.svg)' }}></div>
                <div className={styles.descBox}>
                <div className={classNames(styles.description, i18n.language === 'ar' && styles.arabDes)}>
                    {`${t('leavingGroup.title')} ״${(pathName ? pathName : i18n.language === 'he'? surveyContent.pathHe : surveyContent.pathAr)}״.`}
                </div>
                </div>
                <div className={classNames(styles.description, i18n.language === 'ar' && styles.arabDes, styles.question)}>{t('leavingGroup.why')}</div>

                {[1, 2, 3].map((ans: any) =>
                    < div key={ans} className={classNames(styles.checkboxContent, answer === ans && styles.check)} onClick={() => setAnswer(ans)} >
                        <div className={styles.con}>
                            < div className={classNames(styles.checkbox, answer === ans && styles.checkboxClick)}>
                                {answer === ans && <div className={styles.circel} />}
                            </div>
                            <div className={classNames(styles.text,i18n.language === 'ar' && styles.arabTxt)}>{ans === 1 ? t('leavingGroup.ans1') : ans === 2 ? t('leavingGroup.ans2') : t('leavingGroup.ans3')}</div>
                        </div>
                        {ans === 3 && <input className={styles.input} onChange={(e: any) => setMsg(e.target.value)} maxLength={300} />}
                    </div >
                )}
                <div className={classNames(styles.btn, enable && styles.reveal)} onClick={handleClick}>
                    <div className={styles.vcheck} style={{ backgroundImage: 'url(/assets/inner_pratim_ishiim/done-btn-icon.svg)' }}></div>
                </div>
            </div>
        </div>
    )
}