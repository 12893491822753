import {useEffect, useState} from "react";
import styles from "./myPath.module.scss";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import {DetailsGroup} from "../detailsGroup/detailsGroup";
import {ModalWaiting} from "../modalWaiting/modalWaiting";
import {Loader} from "../loader/loader";
import {WarningPopUp} from "../warningPopUp/warningPopUp";
import {LeaveGroup} from "../leaveGroup/leaveGroup";
import {WhatsAppLinkPopup} from "../whatsAppLinkPopup/whatsAppLinkPopup";
const axios = require("axios").default;

type Props = {
	name: string;
	title: string;
	id: string;
	whatsAppInviteLink: string;
	state: string;
	syllabus: any;
	updatedList: boolean;
	setUpdatedList: React.Dispatch<React.SetStateAction<boolean>>;
};

export const MyPath: React.FC<Props> = ({name, title, id, whatsAppInviteLink, syllabus, setUpdatedList, updatedList, state}) => {
	const [t] = useTranslation("common");
	const [isPassIndex, setIsPassIndex] = useState(0);
	const [showOption, setShowOption] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [showModalWaiting, setShowModalWaiting] = useState(false);
	const [showWarning, setShowWarning] = useState(false);
	const [showWhatsAppLink, setShowWhatsAppLink] = useState(false);
	const [leaveGroup, setLeaveGroup] = useState(false);
	const [checkValue, setCheckValue] = useState(false);
	const [joinStatus, setJoinStatus] = useState("");
	const time = syllabus[isPassIndex != -1 ? isPassIndex : 0].dateTime.slice(0, 10);
	const checkDay = new Date(syllabus[0].dateTime);
	const [year, month, day] = time.split("-");
	const today = new Date();
	const [link, setLink] = useState("");

	useEffect(() => {
		if (link === "") setLink(whatsAppInviteLink);
	}, []);

	useEffect(() => {
		let d;
		syllabus.forEach((topic: any, i: number) => {
			d = new Date(topic.dateTime);
			if (d <= today) {
				setIsPassIndex(i);
			}
		});
		if (checkDay > today) {
			setIsPassIndex(-1);
		}
		if (checkValue || showModal || showModalWaiting || showWarning || leaveGroup) {
			document.body.style.overflowY = "hidden";
		} else {
			document.body.style.overflowY = "unset";
		}
	}, [checkValue, showModalWaiting, showModal, showWarning, leaveGroup]);

	const joinGroup = () => {
		setCheckValue(true);
		axios
			.post("/api/Groups/JoinGroupsMatch", `"${id}"`, {headers: {"Content-Type": "application/json"}})
			.then((res: any) => {
				setCheckValue(false);
				setShowModal(false);
				setShowModalWaiting(true);
				setJoinStatus(res.data.content);
				if (joinStatus !== "queue" && joinStatus !== "added") setLink(res.data.content);
			})
			.catch((err: any) => console.log(err));
	};

	return (
		<div className={styles.card}>
			{showOption && <div className={styles.close} onClick={() => setShowOption(false)}></div>}
			{!showModal && checkValue && <Loader />}
			{showModal && <DetailsGroup setShowModal={setShowModal} showModal={showModal} nameGroup={name} id={id} title={title} syllabus={syllabus} state={state} joinGroup={joinGroup} checkValue={checkValue} />}
			{showModalWaiting && <ModalWaiting status={joinStatus} success={joinStatus !== "queue"} setShowModal={setShowModalWaiting} setShowWhatsAppLink={setShowWhatsAppLink} whatsAppInviteLink={link} setUpdatedList={setUpdatedList} updatedList={updatedList} />}
			{showWarning && <WarningPopUp setShowModal={setShowWarning} groupMatchId={+id} setLeaveGroup={setLeaveGroup} joined={state} updatedList={updatedList} setUpdatedList={setUpdatedList} />}
			{leaveGroup && <LeaveGroup setShowModal={setLeaveGroup} setUpdatedList={setUpdatedList} updatedList={updatedList} />}
			{showWhatsAppLink && <WhatsAppLinkPopup setShowModal={setShowWhatsAppLink} link={link} setShowModalWaiting={setShowModalWaiting} showModalWaiting={showModalWaiting} />}
			<div className={styles.header}>
				<div className={styles.pathName}>{name}</div>
				<div className={styles.title}>{title.replace(/,/g, ", ")}</div>
				{state !== "selected" && state !== "added" && (
					<div className={styles.dots} onClick={() => setShowOption(!showOption)}>
						<div className={styles.dotsIcon} style={{backgroundImage: showOption ? "url(/assets/inner_maslul/dots-more-selected.svg)" : "url(/assets/inner_maslul/dots-more.svg)"}}></div>
						{showOption && (
							<div className={styles.leave} onClick={() => setShowWarning(true)}>
								<div className={styles.heart} style={{backgroundImage: "url(/assets/inner_groups/ic_leave.svg)"}}></div>
								<div>{t("myPath.leave")}</div>
							</div>
						)}
					</div>
				)}
			</div>
			<div className={styles.devider}></div>

			<div className={styles.body}>
				{isPassIndex !== -1 ? (
					<div className={styles.before}>
						<div className={styles.path}>
							<div className={styles.greentDot} style={{backgroundImage: "url(/assets/inner_groups/green-dot.svg)"}}></div>
							<div className={styles.pathDotGreen}></div>
							<div className={classNames(styles.currentDot, isPassIndex > syllabus.length / 2 && styles.current2)} style={{backgroundImage: "url(/assets/inner_groups/current-dot.svg)"}}></div>
							<div className={styles.pathDotEmpty} style={{backgroundImage: "url(/assets/inner_groups/dottedLine-patern.svg)"}}></div>
							<div className={styles.emptyDot} style={{backgroundImage: "url(/assets/inner_groups/other_dot.svg)"}}></div>
						</div>
						<div className={classNames(styles.boxContent, isPassIndex > syllabus.length / 2 && styles.boxContent2)}>
							<div className={styles.txtStart} dangerouslySetInnerHTML={{__html: syllabus[isPassIndex].message}}></div>
							<div className={styles.time}>{`${day}-${month}-${year}`}</div>
						</div>
					</div>
				) : (
					<div className={styles.before}>
						<div className={styles.path}>
							<div className={styles.emptyDot} style={{backgroundImage: "url(/assets/inner_groups/other_dot.svg)"}}></div>
							<div className={styles.pathDot} style={{backgroundImage: "url(/assets/inner_groups/dottedLine-patern.svg)"}}></div>
							<div className={styles.emptyDot} style={{backgroundImage: "url(/assets/inner_groups/other_dot.svg)"}}></div>
						</div>
						<div className={styles.startPath}>
							<div className={styles.txtStart}>{t("myPath.startAt")}</div>
							<div className={styles.time}>{`${day}-${month}-${year}`}</div>
						</div>
					</div>
				)}
			</div>

			<div className={classNames(styles.devider, styles.div2)}></div>
			<div className={styles.footer}>
				<div className={styles.details} onClick={() => setShowModal(true)}>
					{t("myPath.details")}
				</div>
				<div className={styles.content}>
					{state === "added" && link !== "" && (
						<div className={styles.whatsAppButton} onClick={e => setShowWhatsAppLink(true)}>
							<div className={styles.whatsAppIcon} style={{backgroundImage: "url(/assets/inner_maslul/icon-whatsapp.svg)"}}></div>
							<div className={styles.whatsAppGroup}>{t("myPath.connect")}</div>
						</div>
					)}
					{state === "selected" ? (
						<div className={styles.whatsAppButton} onClick={joinGroup}>
							<div className={styles.whatsAppIcon} style={{backgroundImage: "url(/assets/inner_maslul/icon-whatsapp.svg)"}}></div>
							<div className={styles.whatsAppGroup}>{t("myPath.join")}</div>
						</div>
					) : (
						state === "queue" && (
							<div className={styles.waitBox}>
								<div className={styles.waitCircle}></div>
								<div className={styles.waiting}>{t("myPath.waiting")}</div>
								<div className={styles.tooltip}>
									{t("myPath.waitingPopUp")}
									<div className={styles.triangle}></div>
								</div>
							</div>
						)
					)}
				</div>
			</div>
		</div>
	);
};
