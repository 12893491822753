import axios from "axios";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useUserContext} from "../../context/UserContext";
import {LeaveGroup} from "../leaveGroup/leaveGroup";
import {Loader} from "../loader/loader";

interface ISurvey {
	id: number;
	pathHe: string;
	pathAr: string;
}

var userPersonalinfo = {
	email: "",
	gender: "",
	phone: "",
};

const defaultSurvey = {
	id: -1,
	pathHe: "",
	pathAr: "",
};

export const Routing = () => {
	const [, i18n] = useTranslation("common");
	const {setUserInfo, setBasicDetails, setPhoneValid, getAvailablePath, selectedPaths} = useUserContext();
	const navigate = useNavigate();
	const [showSurvey, setShowSurvey] = useState(false);
	const [surveys, setSurveys] = useState<Array<ISurvey>>([defaultSurvey]);
	const [done, setDone] = useState(false);

	useEffect(() => {
		axios
			.get("/api/Users/whoami")
			.then((res: any) => {
				const login = res.data.content;
				setUserInfo(login);
				console.log("data:", login);
				if (login.userType !== "student") navigate("/");
				else {
					console.log("before check");

					if (login.leaveSurveyRequest) {
						setSurveys(login.leaveSurveyRequest);
						setShowSurvey(true);
					} else routing();
				}
			})
			.catch((err: any) => {
				console.log("error:", err);
				if (window.location.href.toLocaleLowerCase().indexOf("routing") !== -1) window.location.href = "/";
			});
	}, []);

	useEffect(() => {
		if (!showSurvey && surveys.length && surveys[0].id !== -1) {
			const temp = surveys;
			temp.shift();
			setSurveys(temp);
			setShowSurvey(true);
		}
		if (!surveys.length) setDone(true);
	}, [showSurvey]);

	useEffect(() => {
		if (done && !surveys.length) {
			console.log("done surveys");
			routing();
		}
	}, [done]);

	const routing = () => {
		axios
			.get("/api/Students/GetStudentBasicDetails")
			.then((res: any) => {
				userPersonalinfo = res.data.content;
				console.log(userPersonalinfo, "userPersonalinfo");
				setBasicDetails(userPersonalinfo);
				if (!userPersonalinfo.email && !userPersonalinfo.phone && !userPersonalinfo.gender) {
					navigate("/UserDetails");
				} else
					axios
						.post("/api/Students/isPhoneValid", userPersonalinfo.phone, {headers: {"Content-Type": "application/json"}})
						.then((res1: any) => {
							const phone = res1.data.content;
							setPhoneValid(phone);
							if (phone === "private" || phone === "error" || !userPersonalinfo.email || !userPersonalinfo.phone || !userPersonalinfo.gender) {
								navigate("/EditDetails");
								console.log("data:", phone);
							} else {
								getAvailablePath()
									.then(groups => {
										if (groups.selectedGroupsMatches.length === 0) navigate("/PathSelection");
										else navigate("/MyGroup");
									})
									.catch((err: any) => console.log("error:", err));
							}
						})
						.catch((err: any) => {
							console.log("error:", err);
							setPhoneValid("500");
							navigate("/EditDetails");
						});
			})
			.catch((err: any) => console.log("error details:", err));
	};

	return <>{showSurvey && surveys.length && surveys[0].id !== -1 ? <LeaveGroup setShowModal={setShowSurvey} pathName={i18n.language === "he" ? surveys[0].pathHe : surveys[0].pathAr} groupNum={+surveys[0].id} /> : <Loader />}</>;
};
