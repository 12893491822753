import { useEffect } from 'react';
import styles from './loader.module.scss'

export const Loader = () => {
    let intViewportWidth = window.innerWidth;
    let intViewportHeight = window.innerHeight;

    useEffect(() => {
        document.body.style.overflowY = 'hidden';
        return (() => {
            document.body.style.overflowY = 'unset';
        })
    }, [])

    return (
        <div className={styles.container} style={{ width: intViewportWidth, height: intViewportHeight }}>
            <div className={styles.loader}></div>
        </div>
    )
}