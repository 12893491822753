import axios from "axios";
import {createContext, useCallback, useContext, useState} from "react";

interface IUserInfo {
	firstName: string;
	isLoggedIn: boolean;
	lastName: string;
	leaveSurveyRequest: any[];
	userType: string;
}

interface IUserDetails {
	email: string;
	phone: string;
	gender: string;
	sector?:string;
}

interface IGroup {
	slotCount: number;
	selectedGroupsMatches: any[];
	pathSteps: any[];
}

interface ISurvey {
	id: number;
	pathHe: string;
	pathAr: string;
}

interface IUserContextProps {
	userInfo: IUserInfo;
	setUserInfo: (val: any) => void;
	basicDetails: IUserDetails;
	setBasicDetails: (val: any) => void;
	phoneValid: string;
	setPhoneValid: (val: string) => void;
	selectedPaths: IGroup;
	getAvailablePath: () => Promise<IGroup>;
	addPath: (data: any) => Promise<IGroup>;
	removePath: (data: any) => Promise<IGroup>;
	surveyContent: ISurvey;
	setSurveyContent: (data: any) => void;
}

const defaultUserInfo = {
	firstName: "",
	isLoggedIn: false,
	lastName: "",
	leaveSurveyRequest: [],
	userType: "",
};

const defaultDetails = {
	email: "",
	phone: "",
	gender: "",
};

const defaultGroups = {
	slotCount: 0,
	selectedGroupsMatches: [],
	pathSteps: [],
};

const defaultSurveyContent = {
	id: -1,
	pathHe: "",
	pathAr: "",
};

export const UserContext = createContext({
	userInfo: defaultUserInfo,
	setUserInfo: (val: IUserInfo) => {},
	basicDetails: defaultDetails,
	setBasicDetails: (val: any) => {},
	phoneValid: "",
	setPhoneValid: (val: string) => {},
	selectedPaths: defaultGroups,
	getAvailablePath: () => {
		return Promise.reject();
	},
	addPath: (data: any) => {
		return Promise.reject();
	},
	removePath: (data: any) => {
		return Promise.reject();
	},
	surveyContent: defaultSurveyContent,
	setSurveyContent: (val: any) => {},
} as IUserContextProps);

export const UserContextProvider = (props: any) => {
	const [userInfo, setUserInfo] = useState<IUserInfo>(defaultUserInfo);
	const [basicDetails, setBasicDetails] = useState(defaultDetails);
	const [phoneValid, setPhoneValid] = useState("");
	const [selectedPaths, setSelectedPaths] = useState(defaultGroups);
	const [surveyContent, setSurveyContent] = useState(defaultSurveyContent);

	const getAvailablePath = useCallback((): Promise<IGroup> => {
		if (selectedPaths.selectedGroupsMatches.length === 0)
			return axios.get("/api/Groups/GetAvailableGroupsMatches").then((res: any) => {
				const data = res.data.content;
				setSelectedPaths(data);
				return data;
			});

		return Promise.reject();
	}, []);

	const addPath = useCallback((data: any): Promise<IGroup> => {
		return axios.post("/api/Groups/SelectGroupsMatch", data, {headers: {"Content-Type": "application/json"}}).then((res: any) => {
			return getAvailablePath();
		});
	}, []);

	const removePath = useCallback((data: any): Promise<IGroup> => {
		const id = data.groupsMatchId || data.groupMatchId;
		return axios.post("/api/Groups/LeaveGroupsMatch", `"${+id}"`, {headers: {"Content-Type": "application/json"}}).then((res: any) => {
			return getAvailablePath();
		});
	}, []);

	const defaultValue = {
		userInfo,
		setUserInfo,
		basicDetails,
		setBasicDetails,
		phoneValid,
		setPhoneValid,
		selectedPaths,
		getAvailablePath,
		addPath,
		removePath,
		surveyContent,
		setSurveyContent,
	};

	return <UserContext.Provider value={defaultValue}>{props.children}</UserContext.Provider>;
};
export const useUserContext = () => useContext(UserContext);
