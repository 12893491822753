import React, { createContext, FC, useContext, useEffect, useState } from 'react';

export enum DeviceType {
    desktop = 'desktop',
    mobile = 'mobile',
}

const SIZE_DESKTOP = { w: window.outerWidth, h: 712 };
// const SIZE_MOBILE = { w: 439, h: 845 };
// const SIZE_MOBILE = { w: 340, h: 605 };
const SIZE_MOBILE = { w: 340 };

const ScaleContext = createContext<{ scale: number; deviceType: DeviceType }>({
    scale: 1,
    deviceType: DeviceType.desktop,
});

export const ScaleProvided: FC = ({ children }) => {
    const deviceType = typeof window.orientation === 'undefined' ? DeviceType.desktop : DeviceType.mobile;

    const [scale, setScale] = useState(1);

    useEffect(() => {

        const resize = () => {
            let width: number;
            if (deviceType === DeviceType.mobile) {
                width = SIZE_MOBILE.w;
                document.body.classList.remove(DeviceType.desktop);
                document.body.classList.add(DeviceType.mobile);
            } else {
                width = SIZE_DESKTOP.w;

                document.body.classList.remove(DeviceType.mobile);
                document.body.classList.add(DeviceType.desktop);
            }

            const iWidth = deviceType === DeviceType.desktop ? window.innerWidth :
                Math.min(window.innerWidth, window.innerHeight);
            const scale = Math.min(iWidth / width);
            const style = document.body.style;
            style.setProperty('--scale', '' + scale);
            setScale(scale);
        };
        resize();

        window.addEventListener('resize', resize);

        return () => {
            window.removeEventListener('resize', resize);
        };
    }, [deviceType]);

    return <ScaleContext.Provider value={{ scale, deviceType }}>{children}</ScaleContext.Provider>;
};

export const useScale = () => useContext(ScaleContext);