import styles from "./settingsMenu.module.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

export const SettingsMenu: React.FC = () => {
    const [t] = useTranslation('common');

    return (
        <div className={styles.scrollContainer}>
            <div className={styles.menuContainer}>
                <div className={styles.textContainer}>
                    <div className={styles.title}>{t('userDetails.settingsMenu.title')}</div>
                    <div className={styles.description}>{t('userDetails.settingsMenu.description')}</div>
                </div>

                <div className={styles.stepsContainer}>
                    <div className={styles.dotted}></div>

                    <div className={styles.steps}>
                        {[1, 2, 3].map(number => {
                            return <div className={styles.row} key={number}>
                                        <div className={styles.circle} >{number}</div>
                                <div key={number} className={classNames(styles.img, styles[`img${number}`])} style={{ backgroundImage: `url(/assets/inner_pratim_ishiim/privacy/Privacy-HE-${number}.png)` }}></div>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}