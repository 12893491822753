import classNames from "classnames";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import styles from "./modalContainer.module.scss";
type Props = {
	setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
	children: any;
	showModal: boolean;
	modalSide?: boolean;
	disableX?: boolean;
	path?: boolean;
	success?: boolean;
};
export const ModalContainer: React.FC<Props> = ({setShowModal, children, showModal, modalSide, disableX, path, success}) => {
	let intViewportWidth = window.innerWidth;
	let intViewportHeight = window.innerHeight;
	const navigate = useNavigate();

	const toggleModal = () => {
		if (disableX) return;
		if (path) navigate("/PathSelection");
		setShowModal && setShowModal(!showModal);
		document.body.style.overflowY = "auto";
	};

	useEffect(() => {
		document.body.style.overflowY = "hidden";
	}, []);

	return (
		<div className={classNames(styles.container)} style={{width: intViewportWidth, height: intViewportHeight}} onClick={toggleModal}>
			{success && <div className={styles.background} style={{backgroundImage: "url(/assets/modal_hodaot/modal_bg_sun.svg)"}} onClick={toggleModal}></div>}
			<div className={classNames(modalSide == null ? styles.contentContainer : modalSide === false && styles.content)}>
				<div className={classNames(modalSide == null ? classNames(styles.popUp, disableX && styles.popUp2, path && styles.path) : modalSide === false && styles.middle)} onClick={e => e.stopPropagation()}>
					{children}
					{modalSide !== false && !disableX && !path && <div className={classNames(styles.close, modalSide && styles.closeSide)} onClick={toggleModal} style={{backgroundImage: "url(/assets/section1-top/close.svg)"}}></div>}
				</div>
			</div>
		</div>
	);
};
