import styles from "./detailsGroup.module.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Loader } from "../loader/loader";

type Props = {
    nameGroup: string,
    id: string,
    title: string
    syllabus: any,
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
    showModal: boolean,
    state: string,
    joinGroup: Function,
    checkValue: boolean
}
export const DetailsGroup: React.FC<Props> = ({ setShowModal, showModal, nameGroup, title, id, syllabus, state, joinGroup, checkValue }) => {
    const [t] = useTranslation('common');
    const [isPassIndex, setIsPassIndex] = useState(0);
    const today = new Date();

    const toggleModal = () => {
        setShowModal(!showModal);
        document.body.style.overflowY = 'auto';
    };

    useEffect(() => {
        document.body.style.overflowY = 'hidden';
        let d;
        var max = 0;
        syllabus.map((topic: any, i: number) => {
            d = new Date(topic.dateTime);
            if (d < today) {
                max = i;
            }
            else if (d === today) {
                max = i;
                return;
            }
        }
        )
        setIsPassIndex(max);
        return () => {
            document.body.style.overflowY = 'auto';
        }
    }, [])

    return (
        <div className={styles.shadowContent} onClick={toggleModal}>
            {checkValue && <Loader />}
            <div className={styles.menuContainer} onClick={e => e.stopPropagation()}>
                <div className={classNames(styles.close)} onClick={toggleModal} style={{ backgroundImage: 'url(/assets/section1-top/close.svg)' }}></div>
                <div className={styles.train} style={{ backgroundImage: 'url(/assets/inner_groups/pop-up-bg.svg)' }}></div>
                <div className={styles.textContainer}>
                    <div className={styles.title}>{nameGroup}</div>
                    <div className={styles.titleDes}>{title.replace(/,/g, ', ')}</div>
                </div>
                <div className={styles.steps}>
                    {syllabus.map((topic: any, i: any) => {
                        const d = new Date(topic.dateTime);
                        const time = topic.dateTime.slice(0, 10);
                        const [, month, day] = time.split('-')
                        var doc = null;
                        if (topic.message.includes('&lt;'))
                            doc = new DOMParser().parseFromString(topic.message, "text/html");
                        return (
                            <div className={styles.boxDetails} key={i}>
                                <div className={classNames(styles.description, styles.date, i===syllabus.length-1 && styles.removeMargin, (today === d || (today > d && i === isPassIndex)) && styles.color)}>{`${day}-${month}`}</div>
                                {today > d ?
                                    i !== isPassIndex ?
                                        <>
                                            <div className={styles.contentPath}>
                                                <div className={styles.emptyDot} style={{ backgroundImage: 'url(/assets/inner_groups/green-dot.svg)' }}></div>
                                            </div>
                                            {(i + 1) !== syllabus.length && <div className={styles.pathDotGreen}></div>}
                                        </> :
                                        <>
                                            <div className={styles.contentPath}>
                                                <div className={styles.currentDot} style={{ backgroundImage: 'url(/assets/inner_groups/current-dot.svg)' }}></div>
                                            </div>
                                            {(i + 1) !== syllabus.length && <div className={styles.pathDotEmpty} style={{ backgroundImage: 'url(/assets/inner_groups/dottedLine-patern.svg)' }}></div>}
                                        </>
                                    :
                                    <>
                                        <div className={styles.contentPath}>
                                            <div className={styles.emptyDot} style={{ backgroundImage: 'url(/assets/inner_groups/other_dot.svg)' }}></div>
                                        </div>
                                        {(i + 1) !== syllabus.length && <div className={styles.pathDotEmptyDot} style={{ backgroundImage: 'url(/assets/inner_groups/dottedLine-patern.svg)' }}></div>}
                                    </>
                                }
                                <div dangerouslySetInnerHTML={{ __html: doc ? doc.documentElement.textContent + '' : topic.message }} className={classNames(styles.description,i===syllabus.length-1 && styles.removeMargin, (today === d || (today > d && i === isPassIndex)) && styles.color)}></div>
                            </div>    
                        )
                    })}
                    <div className={styles.fadeScroll}></div>
                    {state === 'selected' && <div className={styles.boxBtn}>
                        <div className={styles.whatsAppButton} onClick={() => joinGroup()}>
                            <div className={styles.whatsAppIcon} style={{ backgroundImage: 'url(/assets/inner_maslul/icon-whatsapp.svg)' }}></div>
                            <div className={styles.whatsAppGroup}>{t('myPath.join')}</div>
                        </div>
                    </div>}
                </div>
            </div >
        </div>
    )
}