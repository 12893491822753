import {DeviceType, useScale} from "../../../hooks/use-scale";
import {LoginButton} from "../../../components/loginButton/loginButton";
import styles from "./section2.module.scss";
import {useTranslation} from "react-i18next";
import classNames from "classnames";

export const Section2 = () => {
	const {deviceType} = useScale();
	const [t, i18n] = useTranslation("common");

	const getBackground = () => {
		document.body.style.overflowY = "auto";
		return deviceType === DeviceType.mobile ? "url(/assets/section2_icons/m-bg-2.svg)" : "url(/assets/section2_icons/desktop_bg-2.svg)";
	};
	return (
		<div>
			<div id="section2" className={classNames(styles.section2, i18n.language === "ar" && styles.section2Ar)} style={{backgroundImage: getBackground()}}>
				<div className={styles.stickyContainer}>
					<div className={styles.txtExplain}>
						<div className={styles.txtStart}>{t("section2.txtStart")}</div>
						<div className={classNames(styles.txtbold, i18n.language === "ar" && styles.txtboldAr)}>
							{t("section2.txtBold")}
							<div className={classNames(styles.lineWave, i18n.language === "ar" && styles.lineWaveAr)} style={{backgroundImage: "url(/assets/section2_icons/text-wave.svg)"}}></div>
						</div>
						<div className={classNames(styles.txt, i18n.language === "ar" && styles.txtAr)}>
							<div className={classNames(styles.line, i18n.language === "ar" && styles.lineAr)} style={{backgroundImage: "url(/assets/section2_icons/text-underline.svg)"}}></div>
							{t("section2.txtStartBold")
								.trim()
								.split(" ")
								.map((word: string, i: number) => {
									return (
										<span style={{fontWeight: "bold"}} key={i}>
											{word}&nbsp;
										</span>
									);
								})}
							{t("section2.txt")
								.trim()
								.split(" ")
								.map((word: string, i: number) => {
									return <span key={i}>{word}&nbsp;</span>;
								})}
							<div>
								{t("section2.txtEndBold")
									.trim()
									.split(" ")
									.map((word: string, i: number) => {
										return (
											<span key={i} style={{fontWeight: "bold"}}>
												{word}&nbsp;
											</span>
										);
									})}
							</div>
						</div>
					</div>
				</div>
				<div className={classNames(styles.content1, i18n.language === "ar" && styles.content1Ar)}>
					<div className={classNames(styles.sen1, i18n.language === "ar" && styles.sen1Ar)}>{t("section2.sentence1")}</div>
					<div className={classNames(styles.img1, i18n.language === "ar" && styles.img1Ar)} style={{backgroundImage: "url(/assets/section2_icons/img-1.svg)"}}></div>
				</div>
				<div className={classNames(styles.content2, i18n.language === "ar" && styles.content2Ar)}>
					<div className={classNames(styles.sen2, i18n.language === "ar" && styles.sen2Ar)}>{t("section2.sentence2")}</div>
					<div className={classNames(styles.img2, i18n.language === "ar" && styles.img2Ar)} style={{backgroundImage: "url(/assets/section2_icons/img-2.svg)"}}></div>
				</div>
				<div className={classNames(styles.content3, i18n.language === "ar" && styles.content3Ar)}>
					<div className={classNames(styles.sen3, i18n.language === "ar" && styles.sen3Ar)}>{t("section2.sentence3")}</div>
					<div className={classNames(styles.img3, i18n.language === "ar" && styles.img3Ar)} style={{backgroundImage: "url(/assets/section2_icons/img-3.svg)"}}></div>
				</div>
				{deviceType === DeviceType.mobile ? (
					<div className={classNames(styles.content4, i18n.language === "ar" && styles.content4Ar)} style={{backgroundImage: i18n.language === "ar" ? "url(/assets/section2_icons/m-bg_tip_ar.svg)" : "url(/assets/section2_icons/m-bg_tip.svg)"}}>
						<div className={classNames(styles.sen4, i18n.language === "ar" && styles.sen4Ar)}>{t("section2.sentence4")}</div>
					</div>
				) : (
					<div className={classNames(styles.sen4, i18n.language === "ar" && styles.sen4Ar)}>{t("section2.sentence4")}</div>
				)}
				{deviceType !== DeviceType.mobile && (
					<div className={styles.contantBox}>
						<div className={classNames(styles.box, i18n.language === "ar" && styles.boxAr)}>
							<div className={classNames(styles.iconTeacher, i18n.language === "ar" && styles.iconTeacherAr)} style={{backgroundImage: "url(/assets/section2_icons/img-4.svg)"}}></div>
							<div className={classNames(styles.boxTxt, i18n.language === "ar" && styles.boxTxtAr)}>
								{t("section2.txtTeacher1")
									.trim()
									.split(" ")
									.map((word: string, i: number) => {
										return <span key={i}>{word}&nbsp;</span>;
									})}
								{t("section2.txtTeacherBold1")
									.trim()
									.split(" ")
									.map((word: string, i: number) => {
										return (
											<span style={{fontWeight: "bold"}} key={i}>
												{word}&nbsp;
											</span>
										);
									})}
								{t("section2.txtTeacher2")
									.trim()
									.split(" ")
									.map((word: string, i: number) => {
										return <span key={i}>{word}&nbsp;</span>;
									})}
								{t("section2.txtTeacherBold2")
									.trim()
									.split(" ")
									.map((word: string, i: number) => {
										return (
											<span style={{fontWeight: "bold"}} key={i}>
												{word}&nbsp;
											</span>
										);
									})}
								{t("section2.txtTeacher3")
									.trim()
									.split(" ")
									.map((word: string, i: number) => {
										return <span key={i}>{word}&nbsp;</span>;
									})}
							</div>
						</div>
						<LoginButton />
					</div>
				)}
			</div>
			{deviceType === DeviceType.mobile && (
				<div className={styles.part2}>
					<div className={classNames(styles.box, i18n.language === "ar" && styles.boxAr)}>
						<div className={styles.iconTeacher} style={{backgroundImage: "url(/assets/section2_icons/img-4.svg)"}}></div>
						<div className={classNames(styles.boxTxt, i18n.language === "ar" && styles.boxTxtAr)}>
							{t("section2.txtTeacher1")
								.trim()
								.split(" ")
								.map((word: string, i: number) => {
									return <span key={i}>{word}&nbsp;</span>;
								})}
							{t("section2.txtTeacherBold1")
								.trim()
								.split(" ")
								.map((word: string, i: number) => {
									return (
										<span style={{fontWeight: "bold"}} key={i}>
											{word}&nbsp;
										</span>
									);
								})}
							{t("section2.txtTeacher2")
								.trim()
								.split(" ")
								.map((word: string, i: number) => {
									return <span key={i}>{word}&nbsp;</span>;
								})}
							{t("section2.txtTeacherBold2")
								.trim()
								.split(" ")
								.map((word: string, i: number) => {
									return (
										<span style={{fontWeight: "bold"}} key={i}>
											{word}&nbsp;
										</span>
									);
								})}
							{t("section2.txtTeacher3")
								.trim()
								.split(" ")
								.map((word: string, i: number) => {
									return <span key={i}>{word}&nbsp;</span>;
								})}
						</div>
					</div>
					<LoginButton />
				</div>
			)}
		</div>
	);
};
